import { useState } from "react";
import { Modal, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import BNPLModal from "../BNPLModal/BNPLModal";
import "./PaymentFeatureModal.scss";
import BNPLImage from "../../../../assets/images/bnpl_small_image.png";
import PayInFullImage from "../../../../assets/images/pay_in_full.png";
import Cookies from "js-cookie";
const PaymentFeatureModal = ({
  addProduct,
  ProductTitle,
  handleProductDiscountedPrice,
  visible,
  setPaymentFeatureModal,
  AllBankPlans,
  BAFLCardPlans,
  addProductInstallmentPlan,
  isBNPL,
  selectedBAFLPlan,
  setSelectedBAFLPlan,
  selectedAllBankPlan,
  setSelectedAllBankPlan,
}) => {
  const [installmentModalVisible, setInstallmentModalVisible] = useState(false);
  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");
  const handleBuyNowPayLaterClick = () => {
    setInstallmentModalVisible(true);
  };

  return (
    <div className="paymentFeatureModal">
      <Modal
        open={visible}
        onCancel={() => setPaymentFeatureModal(false)}
        footer={null}
        centered
        closeIcon={<CloseOutlined style={{ color: "black" }} />}
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          padding: "10px 5px",
          opacity: 1,
        }}
        width={440}
      >
        <h2 style={{ textAlign: "left" }}>Select Payment Feature</h2>
        {isBNPL === "Yes" ? (
          <Button
            style={{
              fontFamily: "ZemestroStd-Regular",
              backgroundColor: "#f6f6f6",
              padding: "15px 10px 50px 10px",
              marginBottom: "10px",
              color: "black",
              width: "100%",
            }}
            onClick={handleBuyNowPayLaterClick}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "8px",
                textAlign: "left",
                marginLeft: "-15px",
              }}
            >
              <div>
                <img src={BNPLImage.src} width="50px" alt="bnpl" />
              </div>
              <div
                style={{
                  fontFamily: "ZemestroStd-Regular",
                }}
              >
                Buy Now, Pay Later
                <br />
                <span
                  className="font10"
                  style={{
                    color: mainColor,
                  }}
                >
                  {" "}
                  "For all Bank’s credit cards"
                </span>
              </div>
            </div>
          </Button>
        ) : null}
        <Button
          onClick={addProduct}
          style={{
            backgroundColor: "#f6f6f6",
            fontFamily: "ZemestroStd-Regular",
            padding: "15px 10px 50px 10px",
            width: "100%",
            color: "black",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "8px",
              textAlign: "left",
            }}
          >
            <div>
              <img src={PayInFullImage.src} width="35px" alt="pay in full" />
            </div>
            <div
              style={{
                fontFamily: "ZemestroStd-Regular",
              }}
            >
              Pay in Full
              <br />
              <span
                className="font10"
                style={{
                  color: mainColor,
                }}
              >
                Complete Payment Upfront
              </span>
            </div>
          </div>
        </Button>
      </Modal>

      {installmentModalVisible && isBNPL === "Yes" && (
        <BNPLModal
          ProductTitle={ProductTitle}
          handleProductDiscountedPrice={handleProductDiscountedPrice}
          isBNPL={isBNPL}
          AllBankPlans={AllBankPlans}
          BAFLCardPlans={BAFLCardPlans}
          selectedBAFLPlan={selectedBAFLPlan}
          setSelectedBAFLPlan={setSelectedBAFLPlan}
          selectedAllBankPlan={selectedAllBankPlan}
          setSelectedAllBankPlan={setSelectedAllBankPlan}
          addProduct={addProductInstallmentPlan}
          visible={installmentModalVisible}
          setInstallmentModalVisible={setInstallmentModalVisible}
        />
      )}
    </div>
  );
};

export default PaymentFeatureModal;
