import React, { useState } from "react";
import { Col, Row, Image } from "antd";
import { ZoomInOutlined } from "@ant-design/icons";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "./ImagesGrid.scss";

export default function ImagesGrid({ ProductDetail }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [imageLoaded, setImageLoaded] = useState({});
  const [imageSrcs, setImageSrcs] = useState(
    ProductDetail?.images?.reduce(
      (acc, img, index) => ({
        ...acc,
        [index]: img?.cdn_link,
      }),
      {}
    )
  );

  const handleMouseMove = (event) => {
    const rect = event.target.getBoundingClientRect();
    let x = event.clientX - rect.left;
    let y = event.clientY - rect.top;

    const bufferPercentage = 20;
    const minX = rect.width * (bufferPercentage / 100);
    const maxX = rect.width * (1 - bufferPercentage / 100);
    const minY = rect.height * (bufferPercentage / 100);
    const maxY = rect.height * (1 - bufferPercentage / 100);

    x = Math.max(minX, Math.min(x, maxX));
    y = Math.max(minY, Math.min(y, maxY));

    setCursorPosition({ x, y });
  };

  const onImageLoad = (index) => {
    setImageLoaded((prev) => ({ ...prev, [index]: true }));
  };

  const handleImageError = (index) => {
    setImageSrcs((prevSrcs) => ({
      ...prevSrcs,
      [index]: ProductDetail?.images[index]?.cdn_link,
    }));
  };

  return (
    <>
      <Col className="ImagesGrid">
        <Row style={{ display: "flex", flexWrap: "wrap" }} gutter={[5, 5]}>
          <Col
            className="SelectedImageContainer"
            style={{ width: "100%", position: "relative" }}
          >
            {!imageLoaded[selectedImageIndex] && (
              <div
                style={{
                  width: "100%",
                  height: "400px",
                  backgroundColor: "#f2f3f7",
                  position: "relative",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
              />
            )}
            <div
              className="enlargeButton"
              style={{
                position: "absolute",
                cursor: "pointer",
                left: "10px",
                top: "10px",
                zIndex: 1,
              }}
              onClick={() =>
                NativeFancybox.show(
                  ProductDetail?.images?.map((img) => ({
                    src: img?.cdn_link,
                    type: "image",
                  })),
                  {
                    startIndex: selectedImageIndex,
                  }
                )
              }
            >
              <ZoomInOutlined className="iconStyle" />
              <span className="hoverText">Click to enlarge</span>
            </div>

            <div
              className="imageWrapper"
              style={{
                width: "auto",
                height: "470px",
                overflow: "hidden",
                display: "grid",
                justifyContent: "center",
              }}
              onMouseEnter={() => setHoveredImageIndex(selectedImageIndex)}
              onMouseLeave={() => setHoveredImageIndex(null)}
              onMouseMove={handleMouseMove}
            >
              <Image
                width="auto"
                height="100%"
                alt={ProductDetail?.title || "Default Image"}
                title={ProductDetail?.title || "Default Image"}
                src={imageSrcs[selectedImageIndex]}
                onLoad={() => onImageLoad(selectedImageIndex)}
                onError={() => handleImageError(selectedImageIndex)}
                style={{
                  objectFit: "cover",
                  transform:
                    hoveredImageIndex === selectedImageIndex
                      ? "scale(1.25)"
                      : "scale(1)",
                  transformOrigin: `${cursorPosition.x}px ${cursorPosition.y}px`,
                  transition: "transform .2s",
                  opacity: imageLoaded[selectedImageIndex] ? 1 : 0,
                  top: 0,
                  left: 0,
                  display: !imageLoaded[selectedImageIndex] ? "none" : "block",
                }}
                preview={false}
              />
            </div>
          </Col>

          <Row style={{ width: "100%", marginTop: "10px" }} gutter={[5, 5]}>
            {ProductDetail?.images?.map((img, index) => (
              <Col
                key={index}
                className="ThumbnailContainer"
                style={{ cursor: "pointer" }}
                onClick={() => setSelectedImageIndex(index)}
                span={4}
              >
                <Image
                  width="100%"
                  height="100%"
                  alt={ProductDetail?.title || "Thumbnail Image"}
                  title={ProductDetail?.title || "Thumbnail Image"}
                  src={imageSrcs[index]}
                  preview={false}
                />
              </Col>
            ))}
          </Row>
        </Row>
      </Col>
    </>
  );
}
