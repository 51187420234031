import { Tooltip, Modal, Button, Select, Table, Typography, Image } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import "./BNPLModal.scss";
import InfoIcon from "../../../../assets/Icons/infoIcon.svg";
import IslamicIcon from "../../../../assets/images/islamic-icon.png";
import creaditCards from "../../../../assets/images/creditcards.png";
import islamicImage from "../../../../assets/images/Islamic/islamic-logo.png";
import Cookies from "js-cookie";

const BNPLModal = ({
  ProductTitle,
  handleProductDiscountedPrice,
  addProduct,
  visible,
  setInstallmentModalVisible,
  AllBankPlans,
  BAFLCardPlans,
  selectedBAFLPlan,
  setSelectedBAFLPlan,
  selectedAllBankPlan,
  setSelectedAllBankPlan,
}) => {
  const Text = Typography;
  const { Option } = Select;
  const userType = Cookies.get("user_type");
  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");

  // const [selectedBAFLPlan, setSelectedBAFLPlan] = useState(null);
  // const [selectedAllBankPlan, setSelectedAllBankPlan] = useState(null);

  // Set BAFL plan and clear AllBank plan
  const handleBAFLPlanChange = (value) => {
    setSelectedBAFLPlan(value);
    setSelectedAllBankPlan(null);
  };

  // Set AllBank plan and clear BAFL plan
  const handleAllBankPlanChange = (value) => {
    setSelectedAllBankPlan(value);
    setSelectedBAFLPlan(null);
  };

  const BAFLCardPlanOptions = BAFLCardPlans?.plans.map((plan, index) => (
    <Option key={`bafl-plan-${index}`} value={plan}>
      {plan}
    </Option>
  ));

  const AllBankPlanOptions = AllBankPlans?.plans.map((plan, index) => (
    <Option key={`allbank-plan-${index}`} value={plan}>
      {plan}
    </Option>
  ));

  const dataBAFL = BAFLCardPlans?.processing_fees?.map((feeInfo, index) => ({
    key: index,
    plan: feeInfo.plan,
    fee: feeInfo.fees,
  }));

  const dataABCC = AllBankPlans?.processing_fees?.map((feeInfo, index) => ({
    key: index,
    plan: feeInfo.plan,
    fee: feeInfo.fees,
  }));

  const columns = [
    {
      title: "Installment Plans",
      dataIndex: "plan",
      key: "plan",
      align: "center",
      className: "table-header",
    },
    {
      title: "Processing Fees + FED (One Time)",
      dataIndex: "fee",
      key: "fee",
      align: "center",
      className: "table-header",
    },
  ];

  const handleCheckout = () => {
    if (selectedBAFLPlan) {
      addProduct(selectedBAFLPlan, { is_bnpl: true, is_bafl_plan: true });
    } else if (selectedAllBankPlan) {
      addProduct(selectedAllBankPlan, { is_bnpl: true, is_bafl_plan: false });
    }
  };

  console.log("The Data is ", AllBankPlans, BAFLCardPlans);

  return (
    <Modal
      open={visible}
      onCancel={() => setInstallmentModalVisible(false)}
      footer={null}
      // title={`Total Price: ${handleProductDiscountedPrice}`}
      centered
      closeIcon={<CloseOutlined style={{ color: "black" }} />}
      bodyStyle={{
        padding: "20px 10px",
        opacity: 1,
      }}
      className="BAFL-card-modal"
    >
      <h2
        style={{
          textAlign: "left",
          fontWeight: "500",
          fontSize: "24px",
          color: mainColor,
        }}
      >
        {ProductTitle.split(" | ")[0]}
      </h2>

      <h2
        style={{
          textAlign: "left",
          fontWeight: "500",
          fontSize: "20px",
          color: mainColor,
        }}
      >
        Total Price: {handleProductDiscountedPrice}
      </h2>

      {BAFLCardPlanOptions?.length > 0 &&
      userType !== "islamic" ? (
        <>
          <div className="creditCards">
            <img src={creaditCards.src} alt="creditCards" />
            <h2
              style={{
                textAlign: "left",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
            >
              Bank Alfalah
            </h2>
          </div>

          <Select
            value={selectedBAFLPlan || "Select Installment Plan"}
            onChange={handleBAFLPlanChange}
            dropdownClassName="custom-dropdown"
            style={{
              width: "100%",
              marginBottom: "10px",
              borderRadius: "5px",
              border: "none",
              focus: {
                borderColor: mainColor,
              },
            }}
          >
            {BAFLCardPlanOptions}
          </Select>
          {selectedBAFLPlan && (
            <Text
              className="font20Bold marginBottom10"
              style={{ textAlign: "left" }}
            >
              <span
                style={{ color: "black", fontFamily: "ZemestroStd-Regular" }}
              >
                As Low as :{" "}
              </span>
              <span
                style={{ fontFamily: "ZemestroStd-Medium", color: mainColor }}
              >
                {selectedBAFLPlan?.split("Months |")[1]?.trim()}
              </span>
            </Text>
          )}

          {dataBAFL.length > 0 ? (
            <Table
              columns={columns}
              dataSource={dataBAFL}
              pagination={false}
              className="bnpl-installment-table"
              style={{
                marginBottom: "20px",
                border: "1px solid rgb(235, 235, 235)",
                borderRadius: "5px",
              }}
            />
          ) : null}
          {selectedBAFLPlan && (
            <div className="bnpl-helper-container">
              <span className="marginRight5">
                <Image
                  height="auto"
                  width={20}
                  src={InfoIcon.src}
                  preview={false}
                  alt="info-icon"
                />
              </span>
              <p
                className="bnpl-helper-text font12Bold"
                style={{ marginTop: "2px" }}
              >
                Your instalment-based plan will be booked within 3 working days
                and you will see a charge of {selectedBAFLPlan?.split("/")[0]}{" "}
                appear in your next bill. Your card limit of PKR{" "}
                {handleProductDiscountedPrice} has been reserved against this
                purchase
              </p>
            </div>
          )}
          <div className="bnpl-helper-container">
            <span className="marginRight5">
              <Image
                height="auto"
                width={20}
                src={InfoIcon.src}
                preview={false}
                alt="info-icon"
              />
            </span>
            <p
              className="bnpl-helper-text font12Bold"
              style={{ marginTop: "2px" }}
            >
              ⁠Credit Cards within 3 months of issuance and without available
              limit are ineligible to avail Installments.
            </p>
          </div>
        </>
      ) : null}
      {AllBankPlanOptions?.length > 0 && (
        <>
          <div className="all-banks-credicts-heading">
            <img
              src={islamicImage.src}
              alt="Islamic Logo"
              className="islamic-logo-image"
            />
            <h2>All Bank’s Credit Cards</h2>
          </div>
          <Select
            value={selectedAllBankPlan || "Select Installment Plan"}
            onChange={handleAllBankPlanChange}
            dropdownClassName="custom-dropdown"
            style={{
              width: "100%",
              marginBottom: "10px",
              borderRadius: "5px",
              border: "none",
            }}
          >
            {AllBankPlanOptions}
          </Select>
          {selectedAllBankPlan && (
            <Text
              className="font20Bold marginBottom10"
              style={{ textAlign: "left" }}
            >
              <span
                style={{ color: "black", fontFamily: "ZemestroStd-Regular" }}
              >
                As Low as :{" "}
              </span>
              <span
                style={{ fontFamily: "ZemestroStd-Medium", color: mainColor }}
              >
                {selectedAllBankPlan.split("Months |")[1]?.trim()}
              </span>
            </Text>
          )}

          {dataABCC?.length > 0 && (
            <Table
              columns={columns}
              dataSource={dataABCC}
              pagination={false}
              style={{
                marginBottom: "20px",
                border: "1px solid rgb(235, 235, 235)",
                borderRadius: "5px",
              }}
            />
          )}
          <Text
            className="font12Bold bnpl-helper-text-container"
            style={{ textAlign: "left", marginBottom: "20px" }}
          >
            <span className="marginRight5">
              <Image
                height={20}
                src={InfoIcon.src}
                preview={false}
                alt="info-icon"
              />
            </span>
            <p
              className="bnpl-helper-text font12Bold"
              style={{ marginTop: "2px" }}
            >
              All Bank's Credit Cards on 0% processing fees on all tenures
              <br />
              <span className="font12">
                {" "}
                Maximum Limit of up to 750K based on eligibility criteria
              </span>
            </p>
          </Text>
        </>
      )}
      <Button
        onClick={handleCheckout}
        type="primary"
        disabled={!selectedBAFLPlan && !selectedAllBankPlan}
        style={{
          width: "50%",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          padding: "8px 0px 30px 0px",
          borderRadius: "15px",
        }}
      >
        Checkout
      </Button>
    </Modal>
  );
};

export default BNPLModal;
