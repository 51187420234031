//? Library Imports ------------------------------------------------------------>
import { Row, Col, Carousel, Typography } from "antd";

//? Assets --------------------------------------------------------------------->
import "./ProductsCarousel.scss";
import {
  SlickNextArrow,
  SlickPrevArrow,
} from "../Buttons/CarouselButtons/CarouselButtons";

//? Modules -------------------------------------------------------------------->
import ProductCard from "./ProductCard/ProductCard";
import { useEffect, useState } from "react";
import CustomSkeletonBox from "@/components/CustomSkeletonBox/CustomSkeletonBox";
import OnlyForYouCard from "./ProductCard/OnlyForYouCard/OnlyForYouCard";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

// Custom Next Arrow
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', right: '10px', zIndex: '1' }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon />
    </div>
  );
};

// Custom Previous Arrow
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', left: '10px', zIndex: '1' }}
      onClick={onClick}
    >
      <ArrowBackIosIcon />
    </div>
  );
};

export default function OnlyForYouCarousel({
  categoryName,
  data,
  title,
  userAgent,
  type,
}) {
  //? Hooks ---------------------------------------------------------------------->
  const [loading, setLoading] = useState(true);
  const { isMobile, isDesktop } = userAgent;
  //let noOfSlides = products?.products?.length > 5 ? true : false;

  // console.log(type);
  const settings = {
    // speed: 500,
    swipe: true,
    dots: false,
    autoplay: false,
    infinite: false,
    arrows: true,
    slidesToShow: isDesktop ? 4 : 3,
    slidesToScroll: 3,
    // autoplayspeed: 1500,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  useEffect(() => {
    if (data?.length) {
      setLoading(false);
    }
  }, [data]);
  localStorage.setItem("collection_name", categoryName);

  return (
    <Row
      align="middle"
      justify="center"
      className={
        !isDesktop
          ? "ProductsCarousel products-carousel-container padding0"
          : "ProductsCarousel padding0 products-carousel-container"
      }
    >
      {title ? (
        <Col
          span={24}
          className="sectionHeadingContainer text-center mt-10 mb-10"
        >
          <Text
            className={
              isDesktop
                ? "sectionHeadingBold"
                : "sectionHeadingMobileBold textCenter"
            }
          >
            {title}
          </Text>
        </Col>
      ) : null}

      <Col span={24}>
        <div
          className={
            !isDesktop
              ? "only_for_you_carousel_container paddingLeft5 "
              : "only_for_you_carousel_container "
          }
        >
          {loading ? (
            <div style={{ display: "flex" }}>
              {isMobile ? (
                <>
                  {[1, 2].map((index) => (
                    <div key={index} style={{ flex: 1, marginRight: 16 }}>
                      <CustomSkeletonBox width="100%" height={250} />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {[1, 2, 3, 4, 5].map((index) => (
                    <div key={index} style={{ flex: 1, marginRight: 16 }}>
                      <CustomSkeletonBox width="100%" height={371} />
                    </div>
                  ))}
                </>
              )}
            </div>
          ) : (
            <Carousel {...settings}>
              {data?.length &&
                data?.map((pro, key) => {
                  return (
                    <OnlyForYouCard
                      key={key}
                      type={type}
                      product={pro}
                      categoryName={categoryName}
                      userAgent={userAgent}
                    />
                  );
                })}
            </Carousel>
          )}
        </div>
      </Col>
    </Row>
  );
}
