"use client";
//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
// import Image from "next/image";
import { Card, Carousel, Col, Row, Space, Image, Typography } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";

//? Assets --------------------------------------------------------------------->
import "./MultiUsableComp.scss";
import { useEffect, useState } from "react";
import CustomSkeletonBox from "@/components/CustomSkeletonBox/CustomSkeletonBox";
import ShopByCategoryCard from "./ShopByCategoryCard";
import MultiUseableImageCard from "./MultiUseableImageCard";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function MultiUsableComp({ data, userAgent }) {
  const [loading, setLoading] = useState(true);
  const { isMobile, isDesktop } = userAgent;
  const settings = {
    speed: 500,
    swipe: true,
    dots: false,
    // autoplay: true,
    infinite: false,
    arrows: !isMobile,
    slidesToShow: !isDesktop ? 2.5 : 3.5,
    slidesToScroll: !isDesktop ? 2 : 1,
  };

  useEffect(() => {
    if (data?.features?.length) {
      setLoading(false);
    }
  }, [data?.features]);

  return (
    <Row
      className={
        !isDesktop
          ? "MultiUsableComp featured-collection-container marginTop10 marginBottom20"
          : "MultiUsableComp featured-collection-container marginTop10 marginBottom20"
      }
      style={{
        backgroundImage: `url(${data?.background_img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Col
        span={24}
        className="sectionHeadingContainer"
        style={{ background: data?.section_type === "layout_1" && "#ffffff" }}
      >
        {data?.section_type === "layout_6" ? (
          <Text
            className={
              !isDesktop ? "sectionHeadingMobileBold" : "sectionHeadingBold "
            }
          >
            - {data?.main_title} -
          </Text>
        ) : (
          <Text
            className={
              !isDesktop ? "sectionHeadingMobileBold" : "sectionHeadingBold "
            }
          >
            {data?.main_title}
          </Text>
        )}
      </Col>

      <Col
        span={24}
        style={{ background: data?.section_type === "layout_1" && "#ffffff" }}
        className={data?.section_type === "layout_7" && !isMobile && ""}
      >
        {/* Feature Collection - Grid */}
        {data?.section_type === "layout_1" && (
          <div>
            {loading ? (
              <div>
                {isMobile ? (
                  <>
                    <div style={{ display: "flex", marginBottom: "10px" }}>
                      {[1, 2, 3].map((index) => (
                        <div key={index} style={{ flex: 1, marginRight: 8 }}>
                          <CustomSkeletonBox width="100%" height={130} />
                        </div>
                      ))}
                    </div>
                    <div style={{ display: "flex", marginBottom: "10px" }}>
                      {[1, 2, 3].map((index) => (
                        <div key={index} style={{ flex: 1, marginRight: 8 }}>
                          <CustomSkeletonBox width="100%" height={130} />
                        </div>
                      ))}
                    </div>
                    <div style={{ display: "flex", marginBottom: "10px" }}>
                      {[1, 2, 3].map((index) => (
                        <div key={index} style={{ flex: 1, marginRight: 8 }}>
                          <CustomSkeletonBox width="100%" height={130} />
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ display: "flex", marginBottom: "10px" }}>
                      {[1, 2, 3, 4, 5, 6].map((index) => (
                        <div key={index} style={{ flex: 1, marginRight: 8 }}>
                          <CustomSkeletonBox width="100%" height={150} />
                        </div>
                      ))}
                    </div>
                    <div style={{ display: "flex", marginBottom: "10px" }}>
                      {[1, 2, 3, 4, 5, 6].map((index) => (
                        <div key={index} style={{ flex: 1, marginRight: 8 }}>
                          <CustomSkeletonBox width="100%" height={150} />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <Row
                justify="center"
                align="middle"
                className={!isMobile && "paddingRight40 paddingLeft40 width100"}
              >
                {data?.features?.map((item, index) => (
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={8}
                    xs={8}
                    key={index}
                    style={{
                      borderRight:
                        index === 5 || index === 11
                          ? null
                          : "1px solid #F2F3F7",
                      borderBottom: index < 6 && "1px solid #F2F3F7",
                    }}
                    className="flexCenter cursorPointer hoverEffect padding10 "
                  >
                    <ShopByCategoryCard
                      href={item?.link ? item?.link : ""}
                      imagesrc={item?.img}
                      altText={
                        item?.alt_text
                          ? item?.alt_text
                          : item?.img?.split("/").pop()
                      }
                      title={item?.title ? item?.title : null}
                      userAgent={userAgent}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </div>
        )}

        {/* Carousel Sections */}
        {(data?.section_type === "layout_2" ||
          data?.section_type === "layout_5" ||
          data?.section_type === "layout_7" ||
          data?.section_type === "layout_8") && (
          <div
            className={
              !isDesktop
                ? "carousel_container multiusable-section-carousel paddingLeft5"
                : "carousel_container multiusable-section-carousel"
            }
          >
            <Carousel
              {...settings}
              slidesToShow={
                !isMobile
                  ? data?.section_type === "layout_7"
                    ? 5
                    : data?.features?.length > 4
                    ? 5
                    : 4
                  : data?.section_type === "layout_8"
                  ? 1.6
                  : 2.1
              }
            >
              {loading
                ? isMobile
                  ? [1, 2].map((index) => (
                      <div key={index} style={{ flex: 1, marginRight: 16 }}>
                        <CustomSkeletonBox width="100%" height={250} />
                      </div>
                    ))
                  : [1, 2, 3, 4, 5].map((index) => (
                      <div key={index} style={{ marginRight: 16 }}>
                        <CustomSkeletonBox width="100%" height={371} />
                      </div>
                    ))
                : data?.features?.map((item, index) => (
                    <Col key={index}>
                      <Link
                        href={item?.link ? item?.link : ""}
                        className="width100 displayBlock"
                      >
                        <Card
                          style={{
                            background: "none",
                            padding: "10px",
                          }}
                          className={
                            index % 2 === 0 &&
                            data?.section_type === "layout_5" &&
                            "evenCard"
                          }
                          hoverable={data?.section_type === "layout_7" && false}
                          cover={
                            <MultiUseableImageCard
                              imagesrc={item?.img}
                              altText={
                                item?.alt_text
                                  ? item?.alt_text
                                  : item?.img?.split("/").pop()
                              }
                              userAgent={userAgent}
                            />
                          }
                        />
                      </Link>
                    </Col>
                  ))}
            </Carousel>
          </div>
        )}

        {/* Grid Sections */}
        {data?.section_type === "layout_3" ||
        data?.section_type === "layout_4" ||
        data?.section_type === "layout_6" ? (
          <Row
            align="center"
            justify="center"
            gutter={data?.section_type === "layout_6" ? [0, 0] : [5, 0]}
            className={!isDesktop ? "paddingRight10 paddingLeft5" : ""}
          >
            {loading
              ? [1, 2, 3, 4, 5].map((index) => (
                  <div key={index} style={{ flex: 1, margin: "auto" }}>
                    <CustomSkeletonBox width="90%" height={150} />
                  </div>
                ))
              : data?.features?.map((item, index) => (
                  <Col
                    key={index}
                    className="flexCenter cursorPointer"
                    xl={data?.section_type === "layout_6" ? 5 : 4}
                    lg={data?.section_type === "layout_6" ? 5 : 4}
                    md={data?.section_type === "layout_6" ? 5 : 4}
                    sm={8}
                    xs={8}
                  >
                    <Link
                      href={item?.link ? item?.link : ""}
                      className="width100 displayBlock"
                    >
                      <LazyLoadImage
                        width="100%"
                        height="auto"
                        src={item?.img}
                        preview={false}
                        alt={
                          item?.alt_text
                            ? item?.alt_text
                            : item?.img?.split("/").pop()
                        }
                        loading="lazy"
                      />
                    </Link>
                  </Col>
                ))}
          </Row>
        ) : null}
      </Col>
    </Row>
  );
}
