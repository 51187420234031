// Library Imports
import { useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { Col, Card, Skeleton, Typography, Space, Tag, Image } from "antd";
import NextImage from "next/image";

// Assets, Media & StyleSheets 🚀
import "./OnlyForYouCard.scss";
import DefaultImage from "../../../../../assets/images/defaultImages/alfa-loader.gif";
import Points from "../../../../../assets/images/logos/AlfaPoints.svg";
import { removeCommasAndConvertToNumber } from "../../../../../functions/HelperFunctions/index";

import Link from "next/link";

const { Meta } = Card;
const { Text } = Typography;

const OnlyForYouCard = (props) => {
  // States
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState();
  const [hoverProduct, setHoverProduct] = useState(false);
  const [imageSrc, setImageSrc] = useState(
    props?.product.images[0] + "%3Fwidth%3D150"
  );
  const { isDesktop, isMobile } = props?.userAgent;

  // ⬇ Redux States
  const defaultCountry = useSelector(
    (state) => state.multiLocation?.defaultCountry
  );
  const defaultCurrency = "PKR"
  const discountPercentage = () => {
    const originalPrice = removeCommasAndConvertToNumber(
      product?.variant_detail?.original_price
    );
    const discountedPrice = removeCommasAndConvertToNumber(
      product?.variant_detail?.discounted_price
    );

    if (originalPrice && discountedPrice && originalPrice !== 0) {
      const percentageOff = Math.round(
        ((originalPrice - discountedPrice) / originalPrice) * 100
      );
      return `${percentageOff}% OFF`;
    }

    return "";
  };

  const CD_status = useMemo(
    () => product?.features?.find((feature) => feature?.name === "CD Status"),
    [product?.features]
  );

  const DeliveryTime = useMemo(
    () =>
      product?.features?.find((feature) => feature?.name === "Delivery Time"),
    [product?.features]
  );

  function extractDateAfterHyphen(deliveryDateString) {
    const parts = deliveryDateString?.split(" - ");
    return parts?.length > 1 ? parts[1] : null;
  }

  // Example delivery date from backend
  const deliveryDate = product?.delivery_date;
  const dateAfterHyphen = extractDateAfterHyphen(deliveryDate);

  function parseDate(dateString) {
    if (!dateString) {
      console.error("Invalid date string:", dateString);
      return null;
    }

    const parts = dateString.trim().split(" ");
    if (parts.length !== 3) {
      console.error("Date string does not match expected format:", dateString);
      return null;
    }

    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = monthNames.indexOf(month);

    return new Date(year, monthIndex, day);
  }

  function currentFormatDate() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();

    return `${day} ${month}, ${year}`;
  }

  function isDateWithin10DaysOfDelivery(currentDateStr, deliveryDateStr) {
    const currentDate = parseDate(currentDateStr);
    const deliveryDate = parseDate(deliveryDateStr);

    if (!currentDate || !deliveryDate) {
      return false;
    }

    const diffTime = Math.abs(currentDate - deliveryDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays <= 10;
  }

  const handleImageError = () => {
    setImageSrc(props?.product.images[0] || DefaultImage.src);
  };

  const currentDateStr = currentFormatDate();
  useEffect(() => {
    setProduct(props?.product);
    setLoading(props?.product ? false : true);
  }, [product, defaultCountry, props?.product, props?.type]);

  return (
    <Col
      className="OnlyForYouCard"
      style={{
        width: "100%",
        border: "1px solid #C4C4C4",
        borderRadius: "17px",
      }}
    >
      <Link href={"/" + product?.handle}>
        <Card
          hoverable
          cover={
            <div
              style={{
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                // border: "1px solid #d8d8d8",
                // borderRadius: "5px",
                padding: "10px",
              }}
            >
              <Image
                preview={false}
                alt={product?.title}
                width={60}
                height={60}
                onError={handleImageError}
                src={imageSrc}
              />
              {product?.sold_out && (
                <div
                  style={{
                    backgroundColor: "#37011e",
                    height: "20px",
                    color: "white",
                    textAlign: "center",
                    position: "absolute",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "10px",
                    top: "62px",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  Sold Out
                </div>
              )}
            </div>
          }
        >
          <Skeleton loading={loading} cover active>
            <Meta
              title={
                <Space direction="vertical">
                  <Text className="font8 single-line-text">
                    {product?.title}
                  </Text>
                </Space>
              }
              description={
                <Space direction="vertical" className="width100">
                  <Text
                    delete
                    className="product_price font10"
                    type="secondary"
                  >
                    {defaultCurrency + " "}{" "}
                    {product?.variant_detail?.original_price}
                  </Text>
                  <Text
                    className={
                      !isMobile
                        ? "product_price font10"
                        : "product_price font10"
                    }
                  >
                    <span>{defaultCurrency + " "}</span>
                    <span>
                      {product?.variant_detail?.discounted_price
                        ? product?.variant_detail?.discounted_price
                        : product?.variant_detail?.original_price}
                    </span>
                  </Text>
                  {/* {removeCommasAndConvertToNumber(
                    product?.variant_detail?.discounted_price
                  ) <
                    removeCommasAndConvertToNumber(
                      product?.variant_detail?.original_price
                    ) && ( */}

                  {/* )} */}
                </Space>
              }
            />
          </Skeleton>
        </Card>
      </Link>
    </Col>
  );
};

export default OnlyForYouCard;
