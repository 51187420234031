// "use client";
//? Library Imports ------------------------------------------------------------>
import { Col } from "antd";
import { useMemo } from "react";

//? Modules -------------------------------------------------------------------->
import ImagesGrid from "./ImagesGrid/ImagesGrid";
import ImagesCarousel from "./ImagesCarousel/ImagesCarousel";
// import MediaQueryHook from "../../../../hooks/useMediaQuery/MediaQueryHook";

export default function ProductImagesPanel({ userAgent, ProductDetail }) {
  //? Hooks ---------------------------------------------------------------------->
  // const { isMobile } = MediaQueryHook();
  const { isMobile, isTablet, isDesktop } = userAgent;

  const NoOfSlides = useMemo(
    () => ProductDetail?.images?.length,
    [ProductDetail?.images?.length]
  );
  //? Mobile Image Carousel Settings --------------------------------------------->
  const settings = {
    speed: 500,
    swipe: true,
    dots: false,
    arrows: false,
    // lazyLoad: true,
    infinite: true,

    slidesToScroll: 1,
    adaptiveHeight: true,
    slidesToShow: NoOfSlides > 1 ? 1.1 : 1,

    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: NoOfSlides > 1 ? 1.1 : 1,
        },
      },
    ],
  };

  return (
    <Col className="ProductImagesPanel">
      {isMobile ? (
        <ImagesCarousel
          Images={ProductDetail?.images}
          settings={settings}
          ProductDetail={ProductDetail}
          isMobile={isMobile}
        />
      ) : (
        <ImagesGrid isMobile={isMobile} ProductDetail={ProductDetail} />
      )}
    </Col>
  );
}
