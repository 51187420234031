"use client";

import MultiUsableComp from "@/components/Homepage/MultiUsableComp/MultiUsableComp";
import "../../components/Homepage/HomePage.scss";
import { Button, Col, Row, Typography } from "antd";
import Link from "next/link";
import DynamicButton from "@/components/shared/Buttons/DynamicButton/DynamicButton";

export default function NotFoundPage({ data, userAgent }) {
  const { isMobile, isTablet, isDesktop } = userAgent;
  const { Text } = Typography;

  return (
    <div className="Homepage">
      <div
        style={{
          textAlign: "center",
          background: "#F3F1F1",
          padding: "20px",
          marginTop: "30px",
        }}
      >
        <Row className="CataloguesCarousel">
          <Col span={24} className="sectionHeadingContainer ">
            <img
              height={20}
              style={{ marginRigh: "20px" }}
              src="/error-no-result.png"
              alt="404"
            />{" "}
            <Text
              className={!isDesktop ? "error-404-Mobile" : "error-404-Desktop"}
            >
              !404
            </Text>
          </Col>
        </Row>

        <Row>
          <Col style={{ maxWidth: "500px", margin: "auto" }}>
            <Text>
              That&lsquo;s an error. The requested item isn&lsquo;t available.
              But you can have a look at our{" "}
              <Link href="#">Top selling Brands</Link>
            </Text>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Col style={{ maxWidth: "250px", margin: "auto" }}>
            <Link href="/">
              <DynamicButton
                type="primary"
                name={"Return to Home"}
                className="primary_btn poppinsMedium"
                style={{
                  height: "40px",
                  fontSize: "16px",
                  fontFamily: "ZemestroStd-Medium",
                }}
              />
            </Link>
          </Col>
        </Row>
      </div>
      <>
        {data?.homepage?.map(
          (section, index) =>
            section?.type === "multi_usable_section" && (
              <MultiUsableComp
                key={index}
                data={section}
                userAgent={{ isMobile, isTablet, isDesktop }}
              />
            )
        )}
      </>
    </div>
  );
}
