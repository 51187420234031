"use client";
//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
// import Image from "next/image";
import { Image, Space, Typography } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";

//? Assets --------------------------------------------------------------------->
import "./MultiUsableComp.scss";
import { useEffect, useState } from "react";
import CustomSkeletonBox from "@/components/CustomSkeletonBox/CustomSkeletonBox";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function MultiUseableImageCard({
  userAgent,
  imagesrc,
  altText,
}) {
  const { isMobile, isDesktop } = userAgent;

  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageSrc, setImageSrc] = useState(
    imagesrc + `${isMobile ? "%3Fwidth%3D440" : "%3Fwidth%3D440"}`
  );
  const onImageLoad = () => {
    setImageLoaded(true);
  };
  const handleImageError = () => {
    setImageSrc(imagesrc);
  };
  return (
    <div>
      {/* {!imageLoaded && ( */}
      {/* <div
        style={{
          width: "100%",
          height: isDesktop ? "290px" : "165px",
          backgroundColor: "#e6e4e4",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 10,
          // display: imageLoaded ? "none" : "block",
          borderRadius: "20px",
        }}
      /> */}
      {/* )} */}
      <LazyLoadImage
        width="100%"
        height="auto"
        alt={altText}
        onError={handleImageError}
        onLoad={onImageLoad}
        src={imageSrc}
        preview={false}
        loading="lazy"
      />
    </div>
  );
}
