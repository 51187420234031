import React from "react";
import "./SellerCard.scss";
import { Image, Row, Typography } from "antd";
import PakFlag from "../../../assets/Icons/PakFlag.svg";
import VerifiedSellerIcon from "../../../assets/Icons/verifiedSeller.svg";
import WolrdWideIcon from "../../../assets/Icons/worldWide.svg";
import Link from "next/link";
import Cookies from "js-cookie";

export default function SellerCard({ seller }) {
  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");
  const Text = Typography;
  return (
    <div className="seller-card">
      <div className="seller-info">
        <Row
          style={{
            display: "inline-flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Link href={`/${seller?.handle}`} className="seller-name-container">
            <div className="seller-avatar">
              {" "}
              {seller?.name ? seller?.name.charAt(0) : "N/A"}{" "}
            </div>
            <div className="seller-name">{seller?.name}</div>
          </Link>
        </Row>
        <div className="seller-body">
          {/* <div className="seller-content">
            <Image
              width={20}
              height={20}
              preview={false}
              src={PakFlag.src}
              alt="flag"
            />
            <Text> {seller?.address}</Text>
          </div> */}
          <div className="seller-content">
            <Image
              width={20}
              height={20}
              preview={false}
              src={VerifiedSellerIcon.src}
              alt="varified"
            />
            <Text> Verified Merchant</Text>
          </div>{" "}
          <div className="seller-content">
            <Image
              width={20}
              height={20}
              preview={false}
              src={WolrdWideIcon.src}
              alt="flag"
            />
            <Text> Nationwide shipping</Text>
          </div>
        </div>
      </div>
      {/* <Link href={`https://wa.me/${seller?.phone || 234567}?text=Hello%20there!`} target="_blank" className="send-inquiry-btn">Connect with Merchant</Link> */}
      <Link
        href={`https://wa.me/${
          seller?.phone || 234567
        }?text=Hey%20there!%20Check%20out%20this%20product%20I%20found%20on%20AlfaMall!`}
        target="_blank"
        className="send-inquiry-btn"
      >
        Connect with Merchant
      </Link>
      <Link href={`/${seller?.handle}`} className="seller-profile-btn">
        Merchant Shop
      </Link>
    </div>
  );
}
