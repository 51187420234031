"use client";
//? Library Imports ------------------------------------------------------------>
import Cookies from "js-cookie";
import { Col, Image, Row, Typography } from "antd";
import { useDispatch } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import Head from "next/head";

//? Assets --------------------------------------------------------------------->
import "./ProductPage.scss";

//? Modules -------------------------------------------------------------------->
import SoldOut from "./Modules/SoldOut/SoldOut";
// import AccountStatus from "@/functions/Cookies/Cookies";
import BreadCrumbs from "../shared/BreadCrumbs/BreadCrumbs";
// import MediaQueryHook from "@/hooks/useMediaQuery/MediaQueryHook";
import SimilarProducts from "./Modules/SimilarProducts/SimilarProducts";
import ProductImagesPanel from "./Modules/ProductImagesPanel/ProductImagesPanel";
import ProductDetailPanel from "./Modules/ProductDetailPanel/ProductDetailPanel";
// import Loading from "@/app/loading";
// import FBPixels from "../SEO/FBPixels/FBPixels";
// import GoogleTagManager from "../SEO/GTag/Gtag";
import CustomSkeletonBox from "../CustomSkeletonBox/CustomSkeletonBox";
import CustomParagraphSkeleton from "../CustomSkeletonLines/CustomSkeletonLines";
import useBrowserBackButton from "../globalBackButton/BackButton";
import { setDefaultCountry } from "@/redux/Slices/multiLocationSlice";
import { removeCommasAndConvertToNumber } from "@/functions/HelperFunctions";
import SellerCard from "../shared/SellerCard/SellerCard";
import ProductDescription from "./Modules/ProductDetailPanel/ProductDescription/ProductDescription";
import YouMayLike from "./Modules/YouMayLike/YouMayLikeProducts";
import StaticServicesSection from "../StaticServicesSection/StaticServicesSection";
import StaticProductServicesSection from "../StaticProductServicesSection/StaticProductServicesSection";
import ProductInfo from "./Modules/ProductDetailPanel/ProductInfo/ProductInfo";
import { CheckCircleOutlined } from "@ant-design/icons";
import Warantyicon from "../../assets/Icons/Warantyicon.svg";
import MobileTopNav from "../shared/Header/MobileTopNav/MobileTopNav";
export default function ProductPage({
  handle,
  userAgent,
  ProductDetail,
  RelatedProducts,
  storeId,
  defaultCookiesCurrencyValue,
  defaultCookiesCountryValue,
  // SelectedVariantt,
}) {
  //? Hooks -------------------------------------------------------------------->
  const { isMobile, isTablet, isDesktop } = userAgent;
  // const {
  //   loggedIn,
  //   comverse_customer_id,
  //   comverse_customer_email,
  //   comverse_customer_token,
  // } = AccountStatus();

  //? Redux States --------------------------------------------------------------->

  //? States ------------------------------------------------------------------->

  if (Cookies.get("defaultCurrencyId") !== "23") {
    Cookies.set("defaultCurrencyId", storeId, {
      expires: 365,
    });
  }
  if (Cookies.get("defaultCurrencyName") !== "PKR") {
    Cookies.set("defaultCurrencyName", defaultCookiesCurrencyValue, {
      expires: 365,
    });
  }
  if (Cookies.get("defaultCountry") !== "Pakistan") {
    Cookies.set("defaultCountry", defaultCookiesCountryValue, {
      expires: 365,
    });
  }
  if (Cookies.get("geoLocation") !== "Pakistan") {
    Cookies.set("geoLocation", defaultCookiesCountryValue, {
      expires: 365,
    });
  }

  const defaultCurrencyIdFromCookies =
    storeId || Cookies.get("defaultCurrencyId");
  const defaultCurrencyNameFromCookies =
    defaultCookiesCurrencyValue || Cookies.get("defaultCurrencyName");
  // const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const defaultgeoLocationCountry = Cookies.get("geoLocation");
  // defaultCurrencyName;
  const dispatch = useDispatch();

  const [SelectedVariant, setSelectedVariant] = useState(
    ProductDetail?.variants[0]
  );
  // const [SelectedVariant, setSelectedVariant] = useState(null);
  // const [defaultCountry, setDefaultCountry] = useState(storeId);
  const [relatedProductsRes, setRelatedProducts] = useState(RelatedProducts);
  const [productDetailRes, setProductDetailRes] = useState(ProductDetail);
  const [availableVariants, setAvailableVariants] = useState();
  // const [skeletonLoading, setSkeletonLoading] = useState(false);
  const Text = Typography;
  //? Functions ------------------------------------------------------------------>
  const seller = {
    name: "The Original Bro's",
    rating: "4.5 stars",
    location: "Karachi, Pakistan",
    verification: "Verified Seller",
    shipping: "Nationwide shipping",
  };
  useBrowserBackButton(() => {});
  let collectionName = localStorage?.getItem("collection_name");
  if (!collectionName) {
    collectionName = ProductDetail?.category[0]?.title;
    localStorage?.setItem("collection_name", collectionName);
  }

  let defaultCountryId;

  const fetchData = async (url) => {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return await response.json();
    } catch (error) {
      console.error("API call error:", error);
      throw error;
    }
  };

  const fetchProduct = async (handle) => {
    try {
      const res = await fetch(
        process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/product/${handle}?storefront=${defaultCountryId}`,
        { cache: "no-store" }
      );

      const productDetailRess = await res.json();
      setProductDetailRes(productDetailRess);

      const { options, variants } = productDetailRess;

      // setSelectedVariant(variants[0]);

      if (!res.ok) {
        throw new Error("ProductDetail API Failed!");
      }

      const productOptions = options.map((option) => ({
        id: option.id,
        name: option.name,
        position: option.position,
        values: option.values.split(","),
      }));

      let selectedVariant = {};

      if (productOptions?.length) {
        for (const option of productOptions) {
          const variant = variants.find(
            (variant) =>
              variant["option" + option.position] ===
              option.values.find(
                (val) =>
                  variant["option" + option.position] === val &&
                  variant?.variant_detail?.sold_out === false
              )
          );

          if (variant) {
            selectedVariant = variant;
            break; // Stop the loop if a suitable variant is found
          }
        }
      }

      return {
        ProductDetail: { options, variants },
        SelectedVariant: selectedVariant,
      };
    } catch (error) {
      console.error("ProductDetail API Failed!", error.message);
      throw error;
    }
  };

  const fetchSimilarProducts = async (handle) => {
    const res = await fetch(
      process.env.NEXT_PUBLIC_BACKEND +
        `/storefront/product/related_products/${handle}?limit=${
          isMobile ? 16 : 10
        }&page=1&storefront=${defaultCountryId}`
    );

    if (!res.ok) {
      throw new Error("RelatedProducts Api Failed!");
    }
    const relatedProductsRes = await res.json();
    setRelatedProducts(relatedProductsRes);
  };

  const initializeData = async () => {
    try {
      const geoLocationUrl =
        "https://pro.ip-api.com/json/?fields=61439&key=r8KxA4oLfB365n8";
      const countryDataUrl = `${process.env.NEXT_PUBLIC_BACKEND}/storefront/store_list`;

      const apiData = await fetchData(geoLocationUrl);

      const countryListData = await fetchData(countryDataUrl);

      const matchingCountry = countryListData?.country_list.find(
        (countryItem) => countryItem.country === apiData.country
      );

      if (matchingCountry) {
        Cookies.set("defaultCurrencyName", matchingCountry.currency, {
          expires: 365,
        });

        Cookies.set("defaultCurrencyId", matchingCountry.id, {
          expires: 365,
        });

        // setDefaultCountry(matchingCountry.id);
        defaultCountryId = matchingCountry.id;

        Cookies.set("defaultCountry", matchingCountry.country, {
          expires: 365,
        });

        Cookies.set("geoLocation", matchingCountry.country, {
          expires: 365,
        });

        dispatch(setDefaultCountry(matchingCountry));
        dispatch(changeCountryId(matchingCountry.id));
        dispatch(changeCountry(matchingCountry.country));
        dispatch(changeCountryCode(matchingCountry.short_code));
        dispatch(changeCurrency(matchingCountry.currency));
      } else {
        Cookies.set("defaultCurrencyName", "USD", {
          expires: 365,
        });

        Cookies.set("defaultCurrencyId", "35", {
          expires: 365,
        });
        // setDefaultCountry("35");
        Cookies.set("defaultCountry", "United States", {
          expires: 365,
        });

        Cookies.set("geoLocation", apiData.country, {
          expires: 365,
        });
        defaultCountryId = 35;
        dispatch(setDefaultCountry("United States"));
        dispatch(changeCountryId("35"));
        dispatch(changeCountry("United States"));
        dispatch(changeCurrency("USD"));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const fetchDataAndInitialize = async () => {
      await initializeData();

      await fetchProduct(handle);

      // setProductDetailRes(productDetailRes);
      // setSelectedVariant(SelectedVariant);

      await fetchSimilarProducts(handle);
    };

    if (!defaultCurrencyIdFromCookies) {
      fetchDataAndInitialize();
    }
    // Replace 'newpath' with the path you want to add to the URL
    const baseUrl = window.location.href.split("/")[0];
    // newURL.searchParams.set('paramName', newValue); // Set a query parameter with the new value

    const newPath = `${baseUrl}/${handle}`;

    history.pushState({}, "", newPath);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const availableVariant = ProductDetail?.variants.find((variant) => {
    return variant?.variant_detail?.sold_out === false;
  });

  // Set the available variant as the SelectedVariant
  useEffect(() => {
    if (availableVariant && !ProductDetail?.is_stitch) {
      setAvailableVariants(availableVariant);
    } else {
      setSelectedVariant(availableVariant);
    }
  }, []);

  // useEffect(function () {
  //   if (window?.location?.pathname !== "/") {
  //     document.querySelector(".footer-seo-description").style.display = "none";
  //   } else {
  //     document.querySelector(".footer-seo-description").style.display = "block";
  //   }
  // }, []);

  const handleDiscount = (productDetailRes) => {
    const variant = productDetailRes?.variants?.[0]?.variant_detail;
    const discountedPrice = removeCommasAndConvertToNumber(
      variant?.discounted_price
    );
    const originalPrice = removeCommasAndConvertToNumber(
      variant?.original_price
    );

    return originalPrice > discountedPrice
      ? originalPrice - discountedPrice
      : undefined;
  };
  useEffect(() => {
    if (typeof window !== "undefined" && window?.dataLayer) {
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: "view_item",
        ecommerce: {
          currency: defaultCurrencyNameFromCookies,
          items: [
            {
              item_id: productDetailRes?.id,
              item_name: productDetailRes?.title,
              discount: handleDiscount(productDetailRes),
              item_brand: productDetailRes?.brand[0]?.title ?? "No Brand",
              item_variant: productDetailRes?.variants[0]?.variant_id,
              item_category: collectionName ? collectionName : undefined,
              location_id: defaultgeoLocationCountry,
              price: removeCommasAndConvertToNumber(
                productDetailRes?.variants[0]?.variant_detail?.discounted_price
              ),
            },
          ],
        },
      });
    }
  }, []);
  const Color = useMemo(
    () => ProductDetail?.features?.find((feature) => feature?.name === "Color"),
    [ProductDetail?.features]
  );
  const Fabric = useMemo(
    () =>
      ProductDetail?.features?.find(
        (feature) => feature?.name === "Shirt Fabric"
      ),
    [ProductDetail?.features]
  );
  const productInfo = {
    price: ProductDetail?.variants[0]?.variant_detail?.discounted_price,
    rearCamera: "48",
    display: "6.90-inch",
    ram: "12",
    frontCamera: "10",
    storage: "256",
  };

  return (
    <>
      <div itemtype="https://schema.org/Product" itemscope>
        <meta itemprop="name" content={ProductDetail?.title} />
        {ProductDetail?.images?.map((item, i) => {
          return <link key={i} itemprop="image" href={item?.cdn_link} />;
        })}
        <meta itemprop="description" content={ProductDetail?.description} />
        <div itemprop="offers" itemtype="https://schema.org/Offer" itemscope>
          <link
            itemprop="url"
            href={
              process.env.NEXT_PUBLIC_BASE_DOMAIN + "/" + ProductDetail?.handle
            }
          />
          <meta
            itemprop="availability"
            content={
              productDetailRes?.sold_out
                ? "https://schema.org/OutOfStock"
                : "https://schema.org/InStock"
            }
          />
          <meta
            itemprop="priceCurrency"
            content={defaultCurrencyNameFromCookies}
          />
          <meta
            itemprop="price"
            content={
              ProductDetail?.variants[0]?.variant_detail?.discounted_price
            }
          />
        </div>
        <meta
          itemprop="sku"
          className="schema-sku"
          content={ProductDetail?.variants[0]?.sku}
        />
        <div itemprop="brand" itemtype="https://schema.org/Brand" itemscope>
          <meta itemprop="name" content={ProductDetail?.brand[0]?.title} />
        </div>
      </div>

      <Col
        className={!isDesktop ? "" : "Homepage"}
        style={{ margin: "65px auto" }}
      >
        {isMobile && (
          <div
            style={{
              position: "fixed",
              width: "100%",
              margin: "auto",
              zIndex: "1",
              background: "white",
              padding: "10px 5px",
              top: 90,
            }}
          >
            {isMobile && <MobileTopNav bagButton={true} />}
          </div>
        )}
        <Col className="ProductPage">
          <Col
            flex="auto"
            className={
              isDesktop
                ? "paddingLeft40 paddingRight40 paddingTop15 paddingBottom10 breadCrumbs"
                : ""
            }
          >
            {!defaultCurrencyIdFromCookies || !productDetailRes ? (
              <CustomParagraphSkeleton singleHeading={true} width="100" />
            ) : !isMobile ? (
              <BreadCrumbs
                type="Product"
                userAgent={userAgent}
                title={productDetailRes?.title}
              />
            ) : null}
          </Col>

          {!productDetailRes?.sold_out ? (
            <Col
              span={24}
              className={
                isMobile || isTablet
                  ? ""
                  : isDesktop
                  ? "marginLeft20 marginRight20"
                  : ""
              }
              style={{
                border: "1px solid #e6e6e6",
                borderRadius: "5px",
                padding: "0px 10px 0px 0px",
              }}
            >
              <Row
                style={{ height: "auto", minWidth: "100%", maxWidth: "100%" }}
                className={
                  isMobile || isTablet
                    ? "paddingLeft10 paddingRight10"
                    : isDesktop
                    ? "positionRelative backgroundLight  paddingRight10 "
                    : ""
                }
              >
                <Col
                  flex={isMobile || isTablet ? "100%" : "37%"}
                  style={{
                    borderRight: !isMobile ? "1px solid #e6e6e6" : "none",
                    borderRadius: "5px",
                    padding: "15px 5px",
                  }}
                >
                  {!defaultCurrencyIdFromCookies ||
                  !productDetailRes?.images?.length ? (
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1, marginRight: 8 }}>
                        <CustomSkeletonBox
                          width="100%"
                          height={isMobile ? 350 : 500}
                        />
                      </div>
                    </div>
                  ) : (
                    <ProductImagesPanel
                      userAgent={userAgent}
                      ProductDetail={productDetailRes}
                    />
                  )}
                </Col>

                <Col
                  flex={isMobile || isTablet ? "100%" : "42%"}
                  style={{
                    // top: !isMobile || isTablet ? 15 : 0,
                    // right: isMobile || isTablet ? 0 : "25%",
                    // top: 0,
                    // right: 0,
                    // bottom: 0,
                    // overflowY: "auto",
                    maxHeight: "inherit",
                    padding: !isMobile || isTablet ? "20px 0px 20px 20px" : "",
                    position: "relative",
                    // left:0,
                    // borderTop: "1px solid #e6e6e6",
                    // borderright: "1px solid #e6e6e6",
                    // borderBottom: "1px solid #e6e6e6",
                  }}
                >
                  {!defaultCurrencyIdFromCookies || !productDetailRes ? (
                    <CustomParagraphSkeleton width="100" />
                  ) : (
                    <ProductDetailPanel
                      handle={handle}
                      userAgent={userAgent}
                      ProductDetail={productDetailRes}
                      SelectedVariant={SelectedVariant}
                      setSelectedVariant={setSelectedVariant}
                      availableVariants={availableVariants}
                      collectionName={collectionName}
                    />
                  )}
                </Col>

                {isDesktop ? (
                  <Col
                    flex={isMobile || isTablet ? "100%" : "20%"}
                    style={{
                      top: !isMobile || isTablet ? 30 : 0,
                      right: !isMobile || isTablet ? 0 : 0,
                      bottom: !isMobile || isTablet ? 30 : 0,
                      overflowY: "auto",
                      maxHeight: "inherit",
                      paddingLeft: !isMobile || isTablet ? "20px" : "",
                      position: isMobile || isTablet ? "relative" : "absolute",
                      left: isMobile || isTablet ? "" : "78%",
                    }}
                  >
                    <SellerCard seller={productDetailRes?.vendor} />
                  </Col>
                ) : null}
              </Row>
            </Col>
          ) : (
            <Col
              span={24}
              className={
                !isDesktop
                  ? "SoldOutView"
                  : "SoldOutView marginLeft20 marginRight20 marginTop10"
              }
            >
              <SoldOut
                userAgent={userAgent}
                ProductDetail={productDetailRes}
                SelectedVariant={SelectedVariant}
              />
            </Col>
          )}
        </Col>
        {/* //* Product Description ---------------------------------------------------------> */}
        {isMobile && (
          <StaticProductServicesSection
            userAgent={{ isMobile, isTablet, isDesktop }}
          />
        )}
        <Col
          className={`border borderRadius5 ${
            isMobile ? "" : "padding30 margin20"
          }`}
          flex={"100%"}
        >
          <Row justify="space-between">
            {isDesktop && (
              <Col flex={"100%"}>
                <ProductDescription
                  Color={Color}
                  Fabric={Fabric}
                  userAgent={userAgent}
                  ProductDetail={ProductDetail}
                />
              </Col>
            )}
            {/* {isDesktop && (
              <Col flex={"30%"}>
                <YouMayLike
                  userAgent={userAgent}
                  RelatedProducts={relatedProductsRes}
                />
              </Col>
            )} */}
          </Row>
        </Col>
        {/* ) : null} */}
        {relatedProductsRes?.results?.length > 0 && (
          <Col span={24} style={{ marginBottom: isMobile ? "150px" : "10px" }}>
            <SimilarProducts
              handle={handle}
              userAgent={userAgent}
              RelatedProducts={relatedProductsRes}
            />
          </Col>
        )}
      </Col>
    </>
  );
}
