import React from "react";
import { Row, Col, Typography, Image } from "antd";
import "./StaticProductServicesSection.scss";
import DeliveryIcon from "../../assets/images/productpageiconbank.png";
import CustomerServicesIcon from "../../assets/images/productpageicon4.png";
import EasyReturnIcon from "../../assets/images/productpageicon2.png";

const StaticProductServicesSection = ({ userAgent }) => {
  const { isMobile } = userAgent;
  const Text = Typography;

  return (
    <Row justify="center" align="middle" className="product-service-section">
      <div className="service-section-inner">
        <Col
          span={8}
          className={`service-item ${!isMobile ? "desktop-layout" : ""}`}
        >
          <div className="service-icon">
            <Image
              preview={false}
              src={DeliveryIcon?.src}
              height={50}
              width="auto"
              alt="24/7 Customer Service"
            />
          </div>
          <div className="service-info">
            <Text className={isMobile ? "font10Bold" : "font20Bold"}>
              Pay via any banks card
            </Text>
            {/* <p>Friendly Chat</p> */}
          </div>
        </Col>
        <Col
          span={8}
          className={`service-item ${!isMobile ? "desktop-layout" : ""}`}
        >
          <div className="service-icon">
            <Image
              preview={false}
              src={EasyReturnIcon?.src}
              height={50}
              width="auto"
              alt="Easy Returns"
            />
          </div>
          <div className="service-info">
            <Text className={isMobile ? "font10Bold" : "font20Bold"}>
              Secured Payments
            </Text>
            {/* <p>Authentic Brands</p> */}
          </div>
        </Col>
        <Col
          span={8}
          className={`service-item ${!isMobile ? "desktop-layout" : ""}`}
        >
          <div className="service-icon">
            <Image
              preview={false}
              src={CustomerServicesIcon?.src}
              height={50}
              width={50}
              alt="Fast Delivery"
            />
          </div>
          <div className="service-info">
            <Text className={isMobile ? "font10Bold" : "font20Bold"}>
              100 % Payment Refund
            </Text>
            {/* <p>Easy Returns</p> */}
          </div>
        </Col>
      </div>
    </Row>
  );
};

export default StaticProductServicesSection;
