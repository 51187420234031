"use client";
//? Library Imports ------------------------------------------------------------>
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import QRCode from "react-qr-code";
import Link from "next/link";
import Image from "next/image";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/navigation";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Box } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ProductSizeBottomDrawer from "../ProductSizeBottomDrawer/ProductSizeBottomDrawer";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { logoutReducer } from "@/redux/Slices/accountSlice";
import { customerLogout } from "@/functions/Cookies/Cookies";
import { removeCommasAndConvertToNumber } from "../../../../functions/HelperFunctions/index";
import { showMessageWithCustomClose } from "../../../../functions/HelperFunctions/CustomToast";
import { getCurrentDateTime } from "../../../../functions/HelperFunctions/index";
import { RedoOutlined } from "@ant-design/icons";
import handBag from "../../../../assets/images/CartPage/handbag.png";
import ShareIcon from "../../../../assets/Icons/shareIcon.svg";
import wishlistedIcon from "../../../../assets/Icons/wishlistedIcon.svg";
// import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import "../ProductSizeBottomDrawer/ProductSizeBottomDrawer.scss";

import {
  Col,
  Row,
  Tag,
  Form,
  Spin,
  Input,
  Modal,
  Space,
  Button,
  Divider,
  message,
  Typography,
  InputNumber,
  notification,
} from "antd";

//? Assets --------------------------------------------------------------------->
import {
  HeartFilled,
  HeartOutlined,
  PhoneOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { HiOutlineShoppingBag } from "react-icons/hi";
import Ship from "../../../../assets/images/ProductPage/cod-logo.png";
import Clock from "../../../../assets/images/ProductPage/Clock.svg";
import Return from "../../../../assets/images/ProductPage/Return.svg";
import Secure from "../../../../assets/images/ProductPage/Secure.svg";
import WhatsApp from "../../../../assets/images/ProductPage/Chat.svg";
import WorldWide from "../../../../assets/images/ProductPage/WorldWide.svg";
import OnlineOnly from "../../../../assets/images/ProductPage/OnlineOnly.svg";
import Points from "../../../../assets/images/logos/AlfaPoints.svg";
import "./ProductDetailPanel.scss";

//? Modules -------------------------------------------------------------------->
// import MediaQueryHook from "@/hooks/useMediaQuery/MediaQueryHook";
import DynamicButton from "@/components/Shared/Buttons/DynamicButton/DynamicButton";
import svgLoader from "../../../../assets/images/addToCartAnimation/rotating-arrow.svg";

const ProductOptions = dynamic(() => import("./ProductOptions/ProductOptions"));
const StitchingModule = dynamic(
  () => import("../StitchingModule/StitchingModule"),
  { ssr: false }
);
const ProductDescription = dynamic(() =>
  import("./ProductDescription/ProductDescription")
);
import LoginPage from "@/components/SignUpPage/LoginPage";

//? Redux Reducers ------------------------------------------------------------->
import {
  displayCart,
  updateCartCount,
  clearCart,
  updateCart,
} from "@/redux/Slices/cartSlice";
import AccountStatus from "@/functions/Cookies/Cookies";
import { addToWishList, displayWishlist } from "@/redux/Slices/wishListSlice";
import {
  // setCheckoutId,
  clearCheckout,
  setUserInformation,
} from "@/redux/Slices/checkoutSlice";
import PaymentFeatureModal from "@/components/CheckoutPage/Modules/PaymentFeatureModal/PaymentFeatureModal";

// import { setCartId, addToCart, updateCart } from "@/redux/Slices/cartSlice";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function ProductDetailPanel({
  userAgent,
  ProductDetail,
  SelectedVariant,
  setSelectedVariant,
  availableVariants,
  collectionName,
}) {
  //? Redux States --------------------------------------------------------------->
  let cartId = Cookies.get("cartId");
  const FacebookPixelId = Cookies.get("_fbp");
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const imeiCnicRef = useRef(null);
  const formValuesRef = useRef(null);

  const defaultCountryFromCookies = Cookies.get("defaultCountry");
  let defaultgeoLocationCountry = Cookies.get("geoLocation");
  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");
  if (!defaultgeoLocationCountry) {
    defaultgeoLocationCountry = defaultCountryFromCookies;
    Cookies.set("geoLocation", defaultCountryFromCookies, {
      expires: 365,
    });
  }
  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");
  const defaultCurrency = defaultCurrencyFromCookies;
  const StoreId = defaultCurrencyIdFromCookies;
  const cart = useSelector((state) => state?.cartSlice?.cart);
  // const cartId = useSelector((state) => state?.cartSlice?.cartId);
  const wishList = useSelector((state) => state?.wishListSlice?.wishList);
  const comverse_customer_name = useSelector(
    (state) => state?.accountSlice?.comverse_customer_name
  );
  // const comverse_customer_token = useSelector(
  //   (state) => state.accountSlice.comverse_customer_token
  // );

  //? States ------------------------------------------------------------------->
  const [userAgentFromCookies, setuserAgentFromCookies] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [ProductQuantity, setProductQuantity] = useState(1);
  const [ProductInCart, setProductInCart] = useState(false);
  const [ShowNotifyModal, setShowNotifyModal] = useState(false);
  const [ProductInWishList, setProductInWishList] = useState(false);
  const [openSharePopup, setOpenSharePopup] = useState(false);
  const [selectedValueFromOptions, setSelectedValueFromOptions] =
    useState(SelectedVariant);
  const [ShowLoginModal, setShowLoginModal] = useState(false);
  const [selectSizeError, setSelectSizeError] = useState(false);
  const [paymentFeatureModal, setPaymentFeatureModal] = useState(false);
  const [selectedBAFLPlan, setSelectedBAFLPlan] = useState(null);
  const [selectedAllBankPlan, setSelectedAllBankPlan] = useState(null);
  // const [allBankInstallmentPlan, setAllBankInstallmentPlan] = useState(
  //   ProductDetail?.variant_detail?.all_bank_installment_plans
  // );
  const [form] = Form.useForm();

  //? Drawer State ------------------------------------------------------------------->

  const [openDrawer, setOpenDrawer] = useState(false);
  const [viewBagItemsState, setViewBagItemsState] = useState(false);
  const [pushedVariants, setPushedVariants] = useState([]);
  const [addToCartLoader, setaddToCartLoader] = useState(false);

  //? ProductOptions Module States ---------------------------------------------->
  const [LineItemAddons, setLineItemAddons] = useState([]);
  const [TotalAddonPrice, setTotalAddonPrice] = useState(0);

  //? Stitching Module States --------------------------------------------------->
  const [EnableStitching, setEnableStitching] = useState(false);
  const [DefaultStitching, setDefaultStitching] = useState([]); // * Product Stitching Options Selected by Default.
  const [showCopyDiv, setShowCopyDiv] = useState(false);
  const [priorCategoryName, setPriorCategoryName] = useState();
  const [productSoldout, setProductSoldout] = useState(false);
  // const [isSizeChartOpen, setIsSizeChartOpen] = useState(false);

  //? Hooks --------------------------------------------------------------------->
  const router = useRouter();
  const dispatch = useDispatch();
  const uniqueProductId = uuidv4().toString();
  // const { isMobile, isTablet, isDesktop } = MediaQueryHook();
  const { isMobile, isTablet, isDesktop } = userAgent;
  const userType = Cookies.get("user_type");

  let {
    loggedIn,
    comverse_customer_id,
    comverse_customer_email,
    comverse_customer_token,
  } = AccountStatus();
  const myIpAddress = Cookies.get("address");
  const handlePaymentFeatureModalVisible = async () => {
    // debugger;
    try {
      await form.validateFields(["imei_1", "imei_2", "cnic", "customer_name"]);

      if (SelectedVariant && !viewBagItemsState) {
        setPaymentFeatureModal(true);
      } else if (viewBagItemsState) {
        router.push(`/cart/checkout?id=${cartId}`);
        setPaymentFeatureModal(false);
      } else {
        setSelectSizeError(true);
      }
    } catch (errorInfo) {
      if (
        ProductDetail?.is_imei1 &&
        imeiCnicRef.current &&
        isMobile &&
        typeof imeiCnicRef.current.scrollIntoView === "function"
      ) {
        imeiCnicRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  const handlePaymentFeatureModalClose = () => {
    setPaymentFeatureModal(false);
  };
  const showDrawer = () => {
    if (viewBagItemsState) {
      router.push(`/cart/checkout?id=${cartId}`);
    } else {
      if (ProductDetail?.is_stitch) {
        setaddToCartLoader(true);
        addProduct();
        setViewBagItemsState(true);
      } else if (selectedValueFromOptions?.option1 === "-9346535324534") {
        setOpenDrawer(true);
      } else {
        setaddToCartLoader(true);
        addProduct();
        setViewBagItemsState(true);
      }
    }
  };

  const Fabric = useMemo(
    () =>
      ProductDetail?.features?.find(
        (feature) => feature?.name === "Shirt Fabric"
      ),
    [ProductDetail?.features]
  );
  const OnlineAvailable = useMemo(
    () =>
      ProductDetail?.features?.find(
        (feature) => feature?.name === "Online Only"
      ),
    [ProductDetail?.features]
  );

  const CashOnDelivery = useMemo(
    () =>
      ProductDetail?.features?.find(
        (feature) => feature?.name === "Cash On Delivery"
      ),
    [ProductDetail?.features]
  );
  const WorldWideShipping = useMemo(
    () =>
      ProductDetail?.features?.find(
        (feature) => feature?.name === "Worldwide Shipping"
      ),
    [ProductDetail?.features]
  );
  const GiftWrap = useMemo(
    () =>
      ProductDetail?.features?.find((feature) => feature?.name === "Gift Wrap"),
    [ProductDetail?.features]
  );

  const customerEmail = useState(
    useSelector((state) => state.accountSlice.comverse_customer_email)
  );
  const currentDateTime = getCurrentDateTime();
  const [productViewCounter, setProductViewCounter] = useState(1);
  const [url, setUrl] = useState(null);
  //? Functions ------------------------------------------------------------------>

  const handleClickAway = () => {
    setOpenSharePopup(false);
  };
  const handleClick = () => {
    setOpenSharePopup((prev) => !prev);
  };

  const productIncart = useCallback(() => {
    const variantId = SelectedVariant?.variant_detail?.variant_id;
    let variant = cart?.find(
      (item) => item?.productVariant?.variantId === variantId
    );

    if (variant) {
      setProductInCart(true);
    } else {
      setProductInCart(false);
    }
  }, [SelectedVariant, cart]);

  const quantityIncrement = () => {
    if (ProductDetail?.quantity_limit) {
      if (ProductQuantity < ProductDetail?.quantity_limit) {
        setProductQuantity(ProductQuantity + 1);
      } else {
        message.error("Maximum quantity exceeded");
      }
    } else if (!ProductDetail?.quantity_limit) {
      if (ProductQuantity) {
        setProductQuantity(ProductQuantity + 1);
      } else {
        message.error("Maximum quantity exceeded");
      }
    } else {
      message.error("Maximum quantity exceeded");
    }
    // setProductQuantity(ProductQuantity + 1);
  };

  const quantityDecrement = () => {
    if (ProductQuantity > 1) {
      setProductQuantity(ProductQuantity - 1);
    }
  };
  const copyTextToClipboard = async () => {
    setShowCopyDiv(true);
    try {
      await navigator.clipboard.writeText(window.location.href);
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
      // Handle the error or provide user feedback
    }
    setTimeout(() => {
      setShowCopyDiv(false);
    }, 2000);
  };

  const notifyCartUpdate = (type) => {
    if (type === "success") {
      notification[type]({
        message: "Added to Cart!",
      });
    } else {
      notification[type]({
        message: "Removed from Cart!",
      });
    }
  };

  const getWishlist = useCallback(async () => {
    if (loggedIn && comverse_customer_token) {
      try {
        const headers = {
          "Content-Type": "application/json",
        };
        if (comverse_customer_token) {
          headers.Authorization = comverse_customer_token;
        }

        const payload = {
          method: "GET",
          headers: headers,
        };

        const res = await fetch(
          process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/wishlist_list?storefront=${StoreId}`,
          payload
        );
        const data = await res.json();

        if (!res.ok) {
          // message.error("Failed to get Wishlist!");
          if (data?.detail === "Invalid auth token") {
            customerLogout();
            dispatch(logoutReducer());
          }
          throw new Error("Api Failed!");
        } else {
          // message.success("Product Added to Wishlist!");
          const found = data.results?.find(
            (wishListItem) => wishListItem?.id === ProductDetail?.id
          );
          if (found) {
            setProductInWishList(true);
          }
          return data;
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [ProductDetail?.id, StoreId, comverse_customer_token, loggedIn]);

  const addProductToWishlist = async () => {
    if (loggedIn && comverse_customer_token) {
      try {
        setLoading(true);
        const headers = {
          "Content-Type": "application/json",
        };
        if (comverse_customer_token) {
          headers.Authorization = comverse_customer_token;
        }

        const body = {
          storefront: StoreId,
          product_ids: [ProductDetail?.id],
        };

        const payload = {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        };

        const res = await fetch(
          process.env.NEXT_PUBLIC_BACKEND +
          "/storefront/add_product_to_wishlist",
          payload
        );
        const data = await res.json();

        if (!res.ok) {
          // message.error("Failed to add in Wishlist!");
          throw new Error("Api Failed!");
        } else {
          message.success("Product Added to Wishlist!");
          setProductInWishList(true);
          dispatch(displayWishlist(true));
          setLoading(false);
          const itemsData = data?.map((item) => ({
            item_id: item?.id ?? null,
            item_name: item?.title,
            item_variant: item?.variants[0]?.id,
            item_brand: item?.brand[0]?.title,
            price: item?.variants[0]?.variant_detail?.discounted_price,
            location_id: defaultgeoLocationCountry,
          }));

          if (process.env.NEXT_PUBLIC_ENABLE_SCRIPTS === "true") {
            if (typeof window !== "undefined" && window?.dataLayer) {
              dataLayer.push({ ecommerce: null });
              dataLayer.push({
                event: "add_to_wishlist",
                ecommerce: {
                  currency: defaultCurrency ? defaultCurrency : "PKR",
                  Items: itemsData,
                },
              });
            }
          }
          return data;
        }
      } catch (error) {
        console.log("error", error);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } else {
      setShowLoginModal(true);
    }
  };

  const handleOptionChange = (option, check) => {
    // props?.ProductDetail?.variants?.map((item) => {
    //   if (item["option1"] === option) {
    //     props?.setSelectedVariant(item);
    //   }
    // });

    console.log(option, check);

    const selectedVariant = ProductDetail?.variants?.find(
      (item) => item["option1"] === option
    );
    if (selectedVariant && check) {
      const findPushedVariant = pushedVariants?.find(
        (item) => item["option1"] === option
      );
      if (!findPushedVariant) {
        let newArray = [...pushedVariants];
        newArray.push(selectedVariant);
        setPushedVariants([...newArray]);
      }
    } else if (selectedVariant) {
      setSelectedVariant(selectedVariant);
    }
  };

  const removeProductFromWishlist = async () => {
    if (loggedIn && comverse_customer_token) {
      try {
        const headers = {
          "Content-Type": "application/json",
        };
        if (comverse_customer_token) {
          headers.Authorization = comverse_customer_token;
        }

        const body = {
          storefront: StoreId,
        };

        const payload = {
          method: "DELETE",
          headers: headers,
          body: JSON.stringify(body),
        };

        const res = await fetch(
          process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/remove_product_from_wishlist?product_id=${ProductDetail?.id}`,
          payload
        );
        const data = await res.json();

        if (!res.ok) {
          // message.error("Failed to delete from Wishlist!");
          throw new Error("Api Failed!");
        } else {
          setProductInWishList(false);
          message.success("Product Deleted from Wishlist!");
          return data;
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setShowLoginModal(true);
    }
  };

  const productInWishList = useCallback(() => {
    const product = wishList?.find(
      (product) => product?.productDetail?.id === ProductDetail?.id
    );

    if (product) {
      setProductInWishList(true);
    } else {
      setProductInWishList(false);
    }
  }, [ProductDetail?.id, wishList]);

  const clearCartDataAndBuyNow = () => {
    console.log("clearCartDataAndBuyNow");
    dispatch(clearCheckout());
    dispatch(clearCart());
    dispatch(updateCart());
    dispatch(updateCartCount(0));
    Cookies.remove("cartId");
    cartId = Cookies.get("cartId");
    console.log("cartId", cartId);
    buyItNow();
  };

  const buyItNow = async () => {
    console.log("buyItNow called");
    const AddOns = LineItemAddons.map((lineItem) => ({
      option: lineItem?.option,
    }));
    try {
      setLoading(true);
      const body = {
        storefront: StoreId,
        line_item: {
          add_ons: AddOns,
          quantity: ProductQuantity,
          variant_price_inventory: SelectedVariant?.variant_detail?.variant_id,
        },
        ip_country: defaultgeoLocationCountry,
        user_agent: userAgentFromCookies,
      };

      const headers = {
        "Content-Type": "application/json",
      };
      if (cartId) {
        headers.cartid = cartId;
      }
      if (comverse_customer_token) {
        headers.Authorization = comverse_customer_token;
      }

      const payload = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      };

      const AddProduct = await fetch(
        process.env.NEXT_PUBLIC_BACKEND + `/order/add_item_in_cart`,
        payload
      );

      const ProductAdded = await AddProduct.json();

      if (!AddProduct.ok) {
        if (ProductAdded?.detail) {
          if (ProductAdded?.detail === "Maximum quantity exceeded") {
            message.error(ProductAdded?.detail);
          }
          if (ProductAdded?.detail === "cart not found") {
            clearCartDataAndBuyNow();
          }
          if (ProductAdded?.detail === "Invalid auth token") {
            comverse_customer_token = null;
            customerLogout();
            dispatch(logoutReducer());
            addProduct();
          }
          if (ProductAdded?.detail === "inventory quantity not available") {
            message.error("inventory quantity not available");
          }
          if (ProductAdded?.detail === "conflict") {
            message.error("conflict");
          }
        } else {
          // message.error("AddToCart Api Failed!");
        }
        throw new Error("Api Failed!");
      } else if (ProductAdded) {
        setLoading(false);
        message.success("Product Added to Cart!");
        Cookies.set("cartId", ProductAdded?.cartid);
        dispatch(updateCartCount(ProductAdded?.line_items.length));
        router.push(`/cart/checkout?id=${cartId}`);

        return ProductAdded;
      }
    } catch (error) {
      console.error("AddToCart Api Failed!", error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const calculateAddonPrice = useCallback(() => {
    let TotalPrice = 0;
    const AddOns = [];

    LineItemAddons.forEach((lineItem) => {
      ProductDetail.add_on_templates.forEach((template) => {
        template.add_ons.forEach((addOn) => {
          addOn.options.forEach((option) => {
            if (option.id === lineItem.option) {
              AddOns.push(option);
            }
          });
        });
      });
    });

    // console.log("AddOns----->", AddOns);
    AddOns.forEach((addOn) => {
      TotalPrice =
        TotalPrice + removeCommasAndConvertToNumber(addOn?.discounted_price);
    });
    // console.log("TotalPrice----->", TotalPrice);
    setTotalAddonPrice(TotalPrice);
  }, [LineItemAddons, ProductDetail?.add_on_templates]);

  function deepCompareArrays(arr1, arr2) {
    // If the arrays have different lengths, they are not equal
    if (arr1?.length !== arr2?.length) {
      return false;
    }

    // Loop through each object in the array and compare them recursively
    for (let i = 0; i < arr1?.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];

      // If the objects have different keys, they are not equal
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (
        keys1?.length !== keys2?.length ||
        !keys1.every((key) => keys2.includes(key))
      ) {
        return false;
      }

      // Compare the values of each key recursively
      for (const key of keys1) {
        const val1 = obj1[key];
        const val2 = obj2[key];

        if (typeof val1 === "object" && typeof val2 === "object") {
          if (!deepCompareArrays(val1, val2)) {
            return false;
          }
        } else if (val1 !== val2) {
          return false;
        }
      }
    }

    // If we made it this far, the arrays are equal
    return true;
  }

  const showNotificationModal = () => {
    setShowNotifyModal(false);
  };

  const notifyUser = async (data) => {
    try {
      const body = {
        name: data?.full_name,
        email: data?.email,
        phone: data?.phoneNo,
        variant: SelectedVariant?.variant_id,
        ip_country: defaultgeoLocationCountry,
      };

      const payload = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };

      const Notify = await fetch(
        process.env.NEXT_PUBLIC_BACKEND +
        `/storefront/add_product_to_notify_by_user?storefront=${StoreId}`,
        payload
      );

      if (!Notify.ok) {
        throw new Error("NotifyUser API Failed!");
      }

      // Handle the response data accordingly based on your use case
      if (Notify?.ok) {
        setShowNotifyModal(false);

        message.success("Notification Enabled Successfully!");
      } else {
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  const handleFBAddToCartClickEvent = () => {
    let categoryName = Cookies.get("collection_name");
    setPriorCategoryName(categoryName);
    fbq("track", "AddToCartClick", {
      content_name: ProductDetail?.title,
      content_category: priorCategoryName,
      content_ids: SelectedVariant?.variant_id,
      content_type: "product",
      value: SelectedVariant?.variant_detail?.discounted_price,
      currency: "PKR",
    });
  };
  //? AddToCart New Flow --------------------------------------------------------->
  const handleDiscount = (SelectedVariant) => {
    const discountedPrice = removeCommasAndConvertToNumber(
      SelectedVariant?.variant_detail?.discounted_price
    );
    const originalPrice = removeCommasAndConvertToNumber(
      SelectedVariant?.variant_detail?.original_price
    );

    return originalPrice > discountedPrice
      ? originalPrice - discountedPrice
      : undefined;
  };
  const clearCartDataAndAddProduct = async () => {
    // debugger;
    console.log("clearCartDataAndAddProduct");
    // dispatch(clearCheckout());
    // dispatch(clearCart());
    // dispatch(updateCart());
    // dispatch(updateCartCount(0));
    Cookies.remove("cartId");
    cartId = null;
    // return addProduct(formValuesRef.current);
    addProduct();
  };

  const addProduct = async () => {
    console.log("addProduct called");
    // debugger;
    console.log(ProductDetail);
    const values = await form.validateFields();
    // const values = existingValues || (await form.validateFields());

    const imei1 = values.imei_1;
    const imei2 = values.imei_2;
    const cnic = values.cnic;
    const customer_name = values.customer_name;
    const AddOns = LineItemAddons.map((lineItem) => ({
      option: lineItem?.option,
    }));
    let uuid = localStorage.getItem("uuid");
    handleFBAddToCartClickEvent();
    if (SelectedVariant && !viewBagItemsState) {
      setSelectSizeError(false);
      try {
        setLoading(true);
        setaddToCartLoader(true);
        let body = {
          storefront: StoreId,
          line_item: {
            add_ons: AddOns,
            quantity: ProductQuantity,
            item_type: priorCategoryName ? priorCategoryName : null,
            variant_price_inventory:
              SelectedVariant?.variant_detail?.variant_id,
          },
          ip_country: defaultgeoLocationCountry,
          user_agent: userAgentFromCookies,
          // session_uuid: uuid || null
        };

        if (ProductDetail?.is_imei1 && imei1) {
          body.line_item.imei_1 = imei1;
        }
        if (ProductDetail?.is_imei2 && imei2) {
          body.line_item.imei_2 = imei2;
        }
        if (ProductDetail?.is_cnic && cnic) {
          body.line_item.cnic = cnic;
        }
        if (ProductDetail?.is_cnic && customer_name) {
          body.line_item.customer_name = customer_name;
        }

        const headers = {
          "Content-Type": "application/json",
        };
        if (cartId) {
          headers.cartid = cartId;
        }
        if (comverse_customer_token) {
          headers.Authorization = comverse_customer_token;
        }

        // console.log("headers", headers);

        const payload = {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        };

        const AddProduct = await fetch(
          process.env.NEXT_PUBLIC_BACKEND + `/order/add_item_in_cart`,
          payload
        );

        const ProductAdded = await AddProduct.json();
        if (!AddProduct.ok) {
          setViewBagItemsState(false);

          setaddToCartLoader(false);
          if (ProductAdded?.detail) {
            if (ProductAdded?.detail === "Maximum quantity exceeded") {
              message.error(ProductAdded?.detail);
            }
            console.log(ProductAdded?.detail);
            if (ProductAdded?.detail === "cart not found") {
              console.log("cart not found");
              return clearCartDataAndAddProduct();
            }
            if (ProductAdded?.detail === "Invalid auth token") {
              comverse_customer_token = null;

              customerLogout();
              dispatch(logoutReducer());
              addProduct();
            }
            if (ProductAdded?.detail === "inventory quantity not available") {
              message.error("inventory quantity not available");
            }
            if (ProductAdded?.detail !== "conflict") {
              console.log("Toast Called");
              showMessageWithCustomClose(ProductAdded?.detail);
            }
          }
          setLoading(false);
        } else if (ProductAdded) {
          // comverseInsight()
          setViewBagItemsState(true);
          if (process.env.NEXT_PUBLIC_ENABLE_SCRIPTS === "true") {
            if (typeof window !== "undefined" && window?.dataLayer) {
              dataLayer.push({ ecommerce: null });
              dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                  currency: defaultCurrency,
                  value:
                    removeCommasAndConvertToNumber(
                      SelectedVariant?.variant_detail?.discounted_price
                    ) * ProductQuantity,
                  items: [
                    {
                      item_id: ProductDetail?.id,
                      item_name: ProductDetail?.title,
                      item_brand: ProductDetail?.brand[0]?.title ?? "No Brand",
                      item_variant: SelectedVariant?.variant_id,

                      discount: handleDiscount(SelectedVariant),
                      quantity: ProductQuantity,
                      item_category: priorCategoryName
                        ? priorCategoryName
                        : null,
                      price: removeCommasAndConvertToNumber(
                        SelectedVariant?.variant_detail?.discounted_price
                      ),
                      location_id: defaultgeoLocationCountry,
                    },
                  ],
                },
              });
            }

            let vendorTitle = ProductDetail?.brand?.[0]?.title || null;
            let firstImage = ProductDetail?.images?.[0]?.cdn_link || null;

            // if (window.Moengage && window.Moengage.track_event) {
            //   window.Moengage.track_event("add_to_cart", {
            //     "Event Received Time": currentDateTime,
            //     Currency: defaultCurrency ? defaultCurrency : "PKR",
            //     Source: "Website",
            //     Email: customerEmail[0] ? customerEmail[0] : null,
            //     "Product name": ProductDetail?.title,
            //     "Variation ID": SelectedVariant?.variant_id,
            //     "Product Handle": ProductDetail?.handle,
            //     Available: !ProductDetail?.sold_out,
            //     "Product ID": ProductDetail?.id,
            //     Price: SelectedVariant?.variant_detail?.discounted_price,
            //     "Product Brand Name": vendorTitle,
            //     Quantity: ProductQuantity,
            //     hash: ProductAdded?.cartid,
            //     "Product image": firstImage,
            //   });
            // }

            // if (ProductDetail?.pre_order) {
            //   window.Moengage.track_event("prebook_add_to_cart", {
            //     "Event Received Time": currentDateTime,
            //     Currency: defaultCurrency ? defaultCurrency : "PKR",
            //     Source: "Website",
            //     Email: customerEmail[0] ? customerEmail[0] : null,
            //     "Product name": ProductDetail?.title,
            //     "Variation ID": SelectedVariant?.variant_id,
            //     "Product Handle": ProductDetail?.handle,
            //     Available: !ProductDetail?.sold_out,
            //     "Product ID": ProductDetail?.id,
            //     Price: SelectedVariant?.variant_detail?.discounted_price,
            //     "Product Brand Name": vendorTitle,
            //     Quantity: ProductQuantity,
            //     hash: ProductAdded?.cartid,
            //     "Product image": firstImage,
            //   });
            // }
          }
          setTimeout(() => {
            setaddToCartLoader(false);
          }, 1000);
          handleOptionChange(SelectedVariant["option1"], true);

          setLoading(false);
          message.success("Product Added to Cart!");
          Cookies.set("cartId", ProductAdded?.cartid);
          dispatch(updateCartCount(ProductAdded?.line_items.length));
          router.push(`/cart/checkout?id=${cartId}`);
          return ProductAdded;
        }
        setLoading(false);
      } catch (error) {
        debugger;
        console.log(error?.message?.detail);
        showMessageWithCustomClose(error?.message?.detail);
        console.error("AddToCart Api Failed!", error.message);
        setLoading(false);

        // setTimeout(() => {
        //   setLoading(false);
        // }, 1000);
      }
    } else if (viewBagItemsState) {
      router.push(`/cart/checkout?id=${cartId}`);
    } else {
      setSelectSizeError(true);
    }
  };
  useEffect(() => {
    let categoryName = priorCategoryName
      ? priorCategoryName
      : Cookies?.get("collection_name");
    Cookies.remove("collection_name");
    setPriorCategoryName(categoryName);
    // localStorage.setItem("collection_name", categoryName);
    // localStorage.removeItem("collection_name");
    //? Facebook Pixel ----------------------------------------------------------->
    if (
      typeof window !== "undefined" &&
      window.fbq &&
      process.env.NEXT_PUBLIC_ENABLE_SCRIPTS === "true"
    ) {
      window.fbq("track", "ViewContent", {
        source: "Alfamall",
        content_type: "product",
        currency: "PKR",
        content_name: ProductDetail?.title,
        content_id: SelectedVariant?.variant_id,
        content_category: categoryName,
        value: SelectedVariant?.variant_detail?.discounted_price,
      });
    }
  }, [SelectedVariant]);

  // useEffect(() => {
  //   let categoryName = priorCategoryName ? priorCategoryName : Cookies?.get("collection_name")
  //   Cookies.remove("collection_name")
  //   setPriorCategoryName(categoryName);
  //   // localStorage.setItem("collection_name", categoryName);
  //   // localStorage.removeItem("collection_name");
  //   //? Facebook Pixel ----------------------------------------------------------->
  //   if (
  //     typeof window !== "undefined" &&
  //     window.fbq &&
  //     process.env.NEXT_PUBLIC_ENABLE_SCRIPTS === "true"
  //   ) {
  //     window.fbq("track", "ViewContent", {
  //       source: "Alfamall",
  //       content_type: "product",
  //       currency: "PKR",
  //       content_name: ProductDetail?.title,
  //       content_id: SelectedVariant?.variant_id,
  //       content_category: priorCategoryName,
  //       value: SelectedVariant?.variant_detail?.discounted_price,
  //     });
  //   }
  // }, [SelectedVariant]);

  const clearCartDataAndAddProductInstallmentPlan = async (is_bafl_plan) => {
    cartId = null;

    if (selectedBAFLPlan) {
      addProductInstallmentPlan(selectedBAFLPlan, {
        is_bnpl: true,
        is_bafl_plan: is_bafl_plan,
      });
    } else if (selectedAllBankPlan) {
      addProductInstallmentPlan(selectedAllBankPlan, {
        is_bnpl: true,
        is_bafl_plan: is_bafl_plan,
      });
    }
  };

  const addProductInstallmentPlan = async (
    selectedPlan = null,
    paymentOptions = { is_bnpl: false, is_bafl_plan: false }
  ) => {
    const { is_bnpl, is_bafl_plan } = paymentOptions;

    const AddOns = LineItemAddons.map((lineItem) => ({
      option: lineItem?.option,
    }));
    let uuid = localStorage.getItem("uuid");
    const values = await form.validateFields();
    const imei1 = values.imei_1;
    const imei2 = values.imei_2;
    const cnic = values.cnic;
    const customer_name = values.customer_name;

    handleFBAddToCartClickEvent();
    if (SelectedVariant && !viewBagItemsState) {
      setSelectSizeError(false);
      try {
        setLoading(true);
        setaddToCartLoader(true);

        // Determine card type based on the selection
        const card_type = is_bafl_plan ? "bafl_plans" : "all_bank_plans";

        let body = {
          storefront: StoreId,
          line_item: {
            add_ons: AddOns,
            quantity: ProductQuantity,
            item_type: priorCategoryName ? priorCategoryName : null,
            variant_price_inventory:
              SelectedVariant?.variant_detail?.variant_id,
            is_bnpl: is_bnpl,
            card_type: card_type,
            installment_plan: selectedPlan,
          },
          ip_country: defaultgeoLocationCountry,
          user_agent: userAgentFromCookies,
        };

        if (ProductDetail?.is_imei1 && imei1) {
          body.line_item.imei_1 = imei1;
        }
        if (ProductDetail?.is_imei2 && imei2) {
          body.line_item.imei_2 = imei2;
        }
        if (ProductDetail?.is_cnic && cnic) {
          body.line_item.cnic = cnic;
        }
        if (ProductDetail?.is_cnic && customer_name) {
          body.line_item.customer_name = customer_name;
        }

        const headers = {
          "Content-Type": "application/json",
        };
        if (cartId) {
          headers.cartid = cartId;
        }
        if (comverse_customer_token) {
          headers.Authorization = comverse_customer_token;
        }

        const payload = {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        };

        const AddProduct = await fetch(
          process.env.NEXT_PUBLIC_BACKEND + `/order/add_item_in_cart`,
          payload
        );

        const ProductAdded = await AddProduct.json();

        if (!AddProduct.ok) {
          setViewBagItemsState(false);
          setaddToCartLoader(false);
          setLoading(false);
          if (ProductAdded?.detail) {
            if (ProductAdded?.detail === "Maximum quantity exceeded") {
              message.error(ProductAdded?.detail);
            }

            if (ProductAdded?.detail === "cart not found") {
              console.log("is_bafl_plan", is_bafl_plan);
              return clearCartDataAndAddProductInstallmentPlan(is_bafl_plan);
            }
            if (ProductAdded?.detail === "Invalid auth token") {
              comverse_customer_token = null;
              customerLogout();
              dispatch(logoutReducer());
              addProductInstallmentPlan(selectedPlan, paymentOptions);
            }
            if (ProductAdded?.detail === "inventory quantity not available") {
              message.error("inventory quantity not available");
            }
            if (ProductAdded?.detail !== "conflict") {
              showMessageWithCustomClose(ProductAdded?.detail);
            }
          } else {
            throw new Error("Api Failed!");
          }
        } else if (ProductAdded) {
          // comverseInsight()
          setViewBagItemsState(true);
          setTimeout(() => {
            setaddToCartLoader(false);
          }, 1000);
          handleOptionChange(SelectedVariant["option1"], true);

          setLoading(false);
          Cookies.set("cartId", ProductAdded?.cartid);
          dispatch(updateCartCount(ProductAdded?.line_items.length));

          // Track the event with Facebook Pixel or any other analytics platform
          if (process.env.NEXT_PUBLIC_ENABLE_SCRIPTS === "true") {
            if (typeof window !== "undefined" && window?.dataLayer) {
              dataLayer.push({ ecommerce: null });
              dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                  currency: defaultCurrency,
                  value:
                    removeCommasAndConvertToNumber(
                      SelectedVariant?.variant_detail?.discounted_price
                    ) * ProductQuantity,
                  items: [
                    {
                      item_id: ProductDetail?.id,
                      item_name: ProductDetail?.title,
                      item_brand: ProductDetail?.brand[0]?.title ?? "No Brand",
                      item_variant: SelectedVariant?.variant_id,
                      installment_plan: selectedPlan,
                      card_type: is_bafl_plan ? "bafl_plans" : "all_bank_plans",
                      discount: handleDiscount(SelectedVariant),
                      quantity: ProductQuantity,
                      item_category: priorCategoryName
                        ? priorCategoryName
                        : ProductDetail?.category[0]?.title,
                      price: removeCommasAndConvertToNumber(
                        SelectedVariant?.variant_detail?.discounted_price
                      ),
                      location_id: defaultgeoLocationCountry,
                    },
                  ],
                },
              });
            }

            let vendorTitle = ProductDetail?.brand?.[0]?.title || null;
            let firstImage = ProductDetail?.images?.[0]?.cdn_link || null;

            // if (window.Moengage && window.Moengage.track_event) {
            //   window.Moengage.track_event("add_to_cart", {
            //     "Event Received Time": currentDateTime,
            //     Currency: defaultCurrency ? defaultCurrency : "PKR",
            //     Source: "Website",
            //     Email: customerEmail[0] ? customerEmail[0] : null,
            //     "Product name": ProductDetail?.title,
            //     "Variation ID": SelectedVariant?.variant_id,
            //     "Product Handle": ProductDetail?.handle,
            //     Available: !ProductDetail?.sold_out,
            //     "Product ID": ProductDetail?.id,
            //     Price: SelectedVariant?.variant_detail?.discounted_price,
            //     "Product Brand Name": vendorTitle,
            //     Quantity: ProductQuantity,
            //     hash: ProductAdded?.cartid,
            //     "Product image": firstImage,
            //   });
            // }

            // if (ProductDetail?.pre_order) {
            //   window.Moengage.track_event("prebook_add_to_cart", {
            //     "Event Received Time": currentDateTime,
            //     Currency: defaultCurrency ? defaultCurrency : "PKR",
            //     Source: "Website",
            //     Email: customerEmail[0] ? customerEmail[0] : null,
            //     "Product name": ProductDetail?.title,
            //     "Variation ID": SelectedVariant?.variant_id,
            //     "Product Handle": ProductDetail?.handle,
            //     Available: !ProductDetail?.sold_out,
            //     "Product ID": ProductDetail?.id,
            //     Price: SelectedVariant?.variant_detail?.discounted_price,
            //     "Product Brand Name": vendorTitle,
            //     Quantity: ProductQuantity,
            //     hash: ProductAdded?.cartid,
            //     "Product image": firstImage,
            //   });
            // }
          }

          return ProductAdded;
        }
      } catch (error) {
        console.error("AddToCart Api Failed!", error.message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } else if (viewBagItemsState) {
      router.push(`/cart/checkout?id=${cartId}`);
    } else {
      setSelectSizeError(true);
    }
  };

  // User Interactions
  const comverseInsight = async () => {
    const uuid = localStorage.getItem("uuid");
    const url = `${process.env.NEXT_PUBLIC_BACKEND}/user_tracking/interactions`;
    let interactionType = "add_to_cart";
    const data = {
      user_uuid: uuid,
      interaction_type: interactionType,
      path: window.location.pathname,
      country: defaultgeoLocationCountry,
      ip_address: myIpAddress,
      last_interaction_timestamp:
        localStorage.getItem("last_interaction_timestamp") || "",
    };
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //? Facebook Pixel ------------------------------------------------------------->

  useEffect(() => {
    calculateAddonPrice();
  }, [calculateAddonPrice, LineItemAddons]);
  const qrCodeCategoryList = [
    "Digital Lifestyle Branch Zamzama",
    "Sky tower branch",
    "SNSC",
  ];

  const isQrCategory = ProductDetail?.category?.some((category) =>
    qrCodeCategoryList.includes(category.title)
  );

  useEffect(() => {
    // console.log(SelectedVariant);
    // console.log(pushedVariants);
    if (isMobile) {
      const findPushedVariant = pushedVariants?.find(
        (item) => item["option1"] === SelectedVariant?.["option1"]
      );
      if (findPushedVariant) {
        setViewBagItemsState(true);
      } else {
        setViewBagItemsState(false);
      }
    }
  }, [SelectedVariant, pushedVariants, isMobile]);

  useEffect(() => {
    setViewBagItemsState(false);
  }, []);

  useEffect(() => {
    if (loggedIn && comverse_customer_token) {
      getWishlist();
    }
  }, [comverse_customer_token, getWishlist, loggedIn]);

  useEffect(() => {
    return () => {
      dispatch(displayCart(false));
      dispatch(displayWishlist(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (Cookies.get("user_agent")) {
      setuserAgentFromCookies(Cookies.get("user_agent"));
    } else {
      let sessionId =
        Date.now().toString() +
        Math.floor(Math.random() * 1000000000000).toString();
      Cookies.set("user_agent", sessionId);
      setuserAgentFromCookies(sessionId);
    }
  }, []);

  const handleProductDiscountedPrice = () => {
    // .toFixed(2)
    {
      return (
        removeCommasAndConvertToNumber(
          SelectedVariant?.variant_detail?.discounted_price
        ) + removeCommasAndConvertToNumber(TotalAddonPrice)
      ).toLocaleString();
    }
  };

  const handleProductOrignalPrice = () => {
    // .toFixed(2)
    {
      return (
        removeCommasAndConvertToNumber(
          SelectedVariant?.variant_detail?.original_price
        ) + removeCommasAndConvertToNumber(TotalAddonPrice)
      ).toLocaleString();
    }
  };

  const handlePercentageOff = () => {
    const originalPrice = removeCommasAndConvertToNumber(
      SelectedVariant?.variant_detail?.original_price
    );
    const discountedPrice = removeCommasAndConvertToNumber(
      SelectedVariant?.variant_detail?.discounted_price
    );

    if (originalPrice && discountedPrice && originalPrice !== 0) {
      const percentageOff = Math.round(
        ((originalPrice - discountedPrice) / originalPrice) * 100
      );
      return `${percentageOff}% OFF`;
    }

    return ""; // Return an empty string or handle edge cases where the percentage cannot be calculated
  };

  const handleSellerClick = () => {
    const url = `https://wa.me/${ProductDetail?.vendor?.phone}?text=Hello%20there!`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleAddToCartClick = () => {
    const hasEmptyInstallmentPlans =
      !SelectedVariant?.variant_detail?.commission_installment_plans?.plans
        ?.length &&
      !SelectedVariant?.variant_detail?.all_bank_installment_plans?.plans
        ?.length;

    const hasEmptyBankInstallmentPlans =
      !SelectedVariant?.variant_detail?.all_bank_installment_plans?.plans
        ?.length;

    const isIslamicUser =
      userType === "islamic";

    const shouldShowPaymentFeatureModal =
      ProductDetail?.is_bnpl === "Yes" &&
      removeCommasAndConvertToNumber(
        SelectedVariant?.variant_detail?.discounted_price
      ) > 3000 &&
      !hasEmptyInstallmentPlans &&
      !(isIslamicUser && hasEmptyBankInstallmentPlans);
    shouldShowPaymentFeatureModal
      ? handlePaymentFeatureModalVisible()
      : addProduct();
  };

  const handleMainPricePercentageOff = () => {
    const originalPrice = removeCommasAndConvertToNumber(
      availableVariants?.variant_detail?.original_price
    );
    const discountedPrice = removeCommasAndConvertToNumber(
      availableVariants?.variant_detail?.discounted_price
    );

    if (originalPrice && discountedPrice && originalPrice !== 0) {
      const percentageOff = Math.round(
        ((originalPrice - discountedPrice) / originalPrice) * 100
      );
      return `${percentageOff}% OFF`;
    }

    return ""; // Return an empty string or handle edge cases where the percentage cannot be calculated
  };
  useEffect(() => {
    setUrl(window?.location?.href);
  }, [ProductDetail]);

  console.log("Product Details are:", ProductDetail);

  return (
    <Col
      className={
        isMobile || isTablet
          ? "ProductDetailPanel"
          : isDesktop
            ? "ProductDetailPanel"
            : ""
      }
    >
      {/* {!isDesktop && (
        <ProductSizeBottomDrawer
          userAgent={userAgent}
          ProductDetail={ProductDetail}
          SelectedVariant={SelectedVariant}
          setSelectedVariant={setSelectedVariant}
          LineItemAddons={LineItemAddons}
          setLineItemAddons={setLineItemAddons}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          showDrawer={showDrawer}
          addProduct={addProduct}
          setViewBagItemsState={setViewBagItemsState}
          pushedVariants={pushedVariants}
          setPushedVariants={setPushedVariants}
          handleOptionChange={handleOptionChange}
          setaddToCartLoader={setaddToCartLoader}
          selectedValueFromOptions={selectedValueFromOptions}
          setSelectedValueFromOptions={setSelectedValueFromOptions}
          handleProductDiscountedPrice={handleProductDiscountedPrice}
          handleProductOrignalPrice={handleProductOrignalPrice}
        />
      )} */}
      <Spin spinning={Loading} delay={500}>
        <Row className="width100" gutter={[0, 10]}>
          {/* //* Product Title ---------------------------------------------------------------> */}

          <Col
            span={24}
            style={{ padding: isMobile ? "15px 5px 5px 5px" : "0px" }}
            flex={
              // isMobile || isTablet
              // ?
              "99%"
              // : ProductDetail?.images?.length === 1
              //   ? "100%"
              //   : "96%"
            }
          >
            <Space
              className={
                isMobile || isTablet
                  ? "width100 flexBetween"
                  : "width100 flexBetween "
              }
            >
              <Text
                className={
                  isMobile || isTablet
                    ? "title font16 lineHeight22"
                    : "title font20 lineHeight30 "
                }
              >
                <h1 className="product-title">
                  {ProductDetail?.title ? ProductDetail?.title : "N/A"}{" "}
                </h1>
              </Text>
              {isDesktop && (
                <Col className="flexEnd">
                  {ProductInWishList ? (
                    <div
                      style={{
                        backgroundColor: "black",
                        borderRadius: "10px",
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        height={16}
                        width={16}
                        src={wishlistedIcon.src}
                        style={{
                          marginBottom: "2px",
                        }}
                        onClick={() => removeProductFromWishlist()}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: mainColor,
                        borderRadius: "10px",
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        height={16}
                        width={16}
                        src="/heart-icon-1.svg"
                        style={{
                          marginBottom: "2px",
                        }}
                        onClick={() => addProductToWishlist()}
                      />
                    </div>
                  )}
                  {/* {isMobile || isTablet ? null : ( */}
                  <Box alignSelf="center">
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Box sx={{ position: "relative" }}>
                        <div
                          className="cursor-pointer"
                          onClick={handleClick}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            marginLeft: "10px",
                          }}
                        >
                          <Image height={20} width={20} src={ShareIcon} />
                        </div>
                        {openSharePopup && (
                          <Box className="share-icon-popup-style">
                            {showCopyDiv && (
                              <Box>
                                <Typography
                                  style={{ fontSize: "10px" }}
                                  className="copied-text-dialog"
                                >
                                  Copied!
                                </Typography>
                              </Box>
                            )}
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <a
                                href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div style={{ cursor: "pointer" }}>
                                  <FacebookIcon className="small-icon" />
                                </div>
                              </a>

                              <a
                                href={`https://wa.me/?text=I Want to Buy Product - ${ProductDetail?.title} - ${window.location.href}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div style={{ cursor: "pointer" }}>
                                  <WhatsAppIcon className="small-icon" />
                                </div>
                              </a>

                              <a
                                href={`mailto:?subject=${ProductDetail?.title}&body=${window.location.href}`}
                              >
                                <div style={{ cursor: "pointer" }}>
                                  <MailIcon className="small-icon" />
                                </div>
                              </a>

                              <div
                                style={{ cursor: "pointer" }}
                                onClick={copyTextToClipboard}
                              >
                                <ContentCopyIcon className="small-icon" />
                              </div>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </ClickAwayListener>
                  </Box>
                  {/* )} */}
                </Col>
              )}

              {/* //* Mobile View Wishlist Button ---------------------------------------------> */}
            </Space>
          </Col>

          {/* //* Desktop View Product Price ---------------------------------------------------------------> */}

          <Col xs={{ span: 0 }} lg={{ span: 24 }}>
            {SelectedVariant ? (
              <Row>
                {removeCommasAndConvertToNumber(
                  SelectedVariant?.variant_detail?.discounted_price
                ) <=
                  removeCommasAndConvertToNumber(
                    SelectedVariant?.variant_detail?.original_price
                  ) ? (
                  <Row
                    style={{ overflow: "hidden", width: "100%" }}
                    gutter={[10, 0]}
                  >
                    <Col span={24} style={{ padding: "0px" }}>
                      <Space
                        style={{
                          display: "flex",
                          justifyContent: "start", // Ensures inline elements with spacing between them
                          width: "100%", // Ensure full width
                        }}
                      >
                        <Text className="price main-price">
                          {defaultCurrency}&nbsp;
                          {handleProductDiscountedPrice()}
                        </Text>

                        {removeCommasAndConvertToNumber(
                          SelectedVariant?.variant_detail?.discounted_price
                        ) <
                          removeCommasAndConvertToNumber(
                            SelectedVariant?.variant_detail?.original_price
                          ) ? (
                          <Space>
                            <Text className="priceCut">
                              {defaultCurrency}&nbsp;
                              {handleProductOrignalPrice()}
                            </Text>
                            {/* <Tag
            className={
              !isDesktop
                ? "font14 discountTag alignCenter borderNone borderRadius0"
                : "discountTag alignCenter borderNone borderRadius0"
            }
          >
            {handlePercentageOff()}
          </Tag> */}
                          </Space>
                        ) : null}
                      </Space>
                    </Col>
                  </Row>
                ) : null}
              </Row>
            ) : (
              <Row>
                {removeCommasAndConvertToNumber(
                  availableVariants?.variant_detail?.discounted_price
                ) <=
                  removeCommasAndConvertToNumber(
                    availableVariants?.variant_detail?.original_price
                  ) ? (
                  <Row style={{ overflow: "hidden" }} gutter={[10, 0]}>
                    <Col span={24}>
                      <Text className="price main-price font20">
                        {defaultCurrency}
                        &nbsp;
                        {availableVariants?.variant_detail?.discounted_price}
                      </Text>
                      {removeCommasAndConvertToNumber(
                        availableVariants?.variant_detail?.discounted_price
                      ) <
                        removeCommasAndConvertToNumber(
                          availableVariants?.variant_detail?.original_price
                        ) ? (
                        <Space className="paddingLeft20">
                          <Text className="priceCut">
                            {defaultCurrency}
                            &nbsp;
                            {availableVariants?.variant_detail?.original_price}
                          </Text>
                          {/* <Tag
                          className={
                            !isDesktop
                              ? "font14 discountTag alignCenter borderNone borderRadius0"
                              : "discountTag alignCenter borderNone borderRadius0"
                          }
                        >
                          {handleMainPricePercentageOff()}
                        </Tag> */}
                        </Space>
                      ) : null}
                    </Col>
                  </Row>
                ) : null}
                {ProductDetail?.loyalty_points > 0 && (
                  <Space className="marginLeft5">
                    <div
                      style={{
                        height: "20px",
                        display: "flex",
                        padding: "0 5px",
                        minWidth: "58px",
                        alignItems: "center",
                        background: "#2A1E5C",
                        borderRadius: "9px 0px 9px 9px",
                      }}
                    >
                      <Image
                        width={13}
                        height={15}
                        src={Points}
                        alt="LoyaltyPoints"
                      />
                      <span
                        className="font11Bold"
                        style={{ color: "white", paddingLeft: "10px" }}
                      >
                        {ProductDetail?.loyalty_points}
                      </span>
                    </div>
                  </Space>
                )}
              </Row>
            )}
          </Col>

          {/* //* Product SKU, Brand, Fabric -------------------------------------------------> */}

          <Col
            flex={
              isMobile || isTablet
                ? "100%"
                : ProductDetail?.images?.length === 1
                  ? "99%"
                  : "96%"
            }
          >
            <Row
              align="middle"
              gutter={[0, 16]}
              style={{
                width: "100%",
                height: "auto",
                overflow: "hidden",
                justifyContent: "space-between",
                // height: isMobile || isTablet ? "70px" : "auto",
              }}
              className={
                isMobile || isTablet ? "backgroundLight borderRadius5 " : ""
              }
            >
              {isMobile || isTablet ? (
                SelectedVariant ? (
                  <Col flex="0 0 auto">
                    {removeCommasAndConvertToNumber(
                      SelectedVariant?.variant_detail?.discounted_price
                    ) <=
                      removeCommasAndConvertToNumber(
                        SelectedVariant?.variant_detail?.original_price
                      ) ? (
                      <Row
                        gutter={[10, 0]}
                        className="flexColumn"
                        style={{ overflow: "hidden" }}
                      >
                        <Col flex="0 0 auto">
                          <Text className="font16Bold price">
                            {defaultCurrency}
                            &nbsp;
                            {handleProductDiscountedPrice()}
                          </Text>
                          {removeCommasAndConvertToNumber(
                            SelectedVariant?.variant_detail?.discounted_price
                          ) <
                            removeCommasAndConvertToNumber(
                              SelectedVariant?.variant_detail?.original_price
                            ) ? (
                            <Space className="paddingLeft10">
                              <Text className="font16 priceCut">
                                {defaultCurrency}
                                &nbsp;
                                {/* {SelectedVariant?.variant_detail?.original_price
                                  ? parseFloat(
                                      SelectedVariant?.variant_detail
                                        ?.original_price + TotalAddonPrice
                                    ).toFixed(2)
                                  : "N/A"} */}
                                {handleProductOrignalPrice()}
                              </Text>
                              {/* <Tag className="discountTag alignCenter borderNone borderRadius0 font14">
                             
                                {handlePercentageOff()}
                              </Tag> */}
                            </Space>
                          ) : null}
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                ) : (
                  <Col flex="0 0 auto">
                    {removeCommasAndConvertToNumber(
                      availableVariants?.variant_detail?.discounted_price
                    ) <=
                      removeCommasAndConvertToNumber(
                        availableVariants?.variant_detail?.original_price
                      ) ? (
                      <Row
                        gutter={[10, 0]}
                        className="flexRow"
                        style={{ overflow: "hidden" }}
                      >
                        <Col flex="0 0 auto">
                          <Text className="font16Bold price">
                            {defaultCurrency}
                            &nbsp;
                            {
                              availableVariants?.variant_detail
                                ?.discounted_price
                            }
                          </Text>
                        </Col>

                        {removeCommasAndConvertToNumber(
                          availableVariants?.variant_detail?.discounted_price
                        ) <
                          removeCommasAndConvertToNumber(
                            availableVariants?.variant_detail?.original_price
                          ) ? (
                          <Space className="paddingLeft5">
                            <Text className="font16 priceCut">
                              {defaultCurrency}
                              &nbsp;
                              {
                                availableVariants?.variant_detail
                                  ?.original_price
                              }
                            </Text>
                            {/* <Tag className="discountTag alignCenter borderNone borderRadius0 font14">
                              {handleMainPricePercentageOff()}
                            </Tag> */}
                          </Space>
                        ) : null}
                      </Row>
                    ) : null}
                  </Col>
                )
              ) : null}
            </Row>
          </Col>
          <Col
            className={
              isMobile || isTablet
                ? "backgroundLight paddingLeft10 paddingRight10"
                : ""
            }
            flex={
              isMobile || isTablet
                ? "100%"
                : ProductDetail?.images?.length === 1
                  ? "99%"
                  : "96%"
            }
          >
            <Row>
              <Col span={24}>
                <Text
                  className={
                    isDesktop
                      ? "secondary font18 poppinsSemiBold font600"
                      : "font12 secondary font600"
                  }
                >
                  SKU {isDesktop ? <span>:</span> : null}{" "}
                  {SelectedVariant?.sku
                    ? SelectedVariant?.sku
                    : availableVariants?.sku
                      ? availableVariants?.sku
                      : "N/A"}
                </Text>
              </Col>
            </Row>
          </Col>
          {isMobile && (
            <div className="marginLeft10">
              <Row className="border borderRadius5 padding2 marginLeft10">
                <Link
                  href={`/${ProductDetail?.vendor?.handle}`}
                  className="font12Bold"
                  style={{ padding: "5px" }}
                >
                  <span
                    className="font12Bold"
                    style={{
                      color: mainColor,
                      textTransform: "uppercase",
                      marginRight: "10px",
                    }}
                  >
                    {" "}
                    Sold by
                  </span>
                  {ProductDetail?.vendor?.name}
                </Link>
              </Row>
            </div>
          )}

          <Form form={form} layout="vertical" className="extra-details">
            <div ref={imeiCnicRef} className="extra-details-inner">
              {ProductDetail?.is_imei1 && (
                <>
                  <label> IMEI 1 </label>
                  <Form.Item
                    name="imei_1"
                    rules={[
                      { required: true, message: "Please enter valid IMEI 1" },
                      {
                        max: 16,
                        message: "IMEI 1 cannot exceed 16 characters",
                      },
                      () => ({
                        validator(_, value) {
                          if (value && value.length > 16) {
                            return Promise.reject(
                              new Error("IMEI 1 cannot exceed 16 characters")
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Please enter your IMEI 1"
                      type="text"
                      maxLength={16}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  </Form.Item>
                </>
              )}
              {ProductDetail?.is_imei2 && (
                <>
                  <label> IMEI 2 </label>
                  <Form.Item
                    name="imei_2"
                    rules={[
                      { required: true, message: "Please enter valid IMEI 2" },
                      {
                        max: 16,
                        message: "IMEI 2 cannot exceed 16 characters",
                      },
                      () => ({
                        validator(_, value) {
                          if (value && value.length > 16) {
                            return Promise.reject(
                              new Error("IMEI 2 cannot exceed 16 characters")
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Please enter your IMEI 2"
                      type="text"
                      maxLength={16}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  </Form.Item>
                </>
              )}
              {ProductDetail?.is_cnic && (
                <>
                  <label> Customer Name </label>
                  <Form.Item
                    name="customer_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid Customer Name",
                      },
                      {
                        max: 100,
                        message: "Customer Name cannot exceed 100 characters",
                      },
                      () => ({
                        validator(_, value) {
                          if (value && value.length > 99) {
                            return Promise.reject(
                              new Error(
                                "Customer Name cannot exceed 100 characters"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Please enter your name as per CNIC"
                      type="text"
                      maxLength={100}
                    />
                  </Form.Item>
                </>
              )}
              {ProductDetail?.is_cnic && (
                <>
                  <label> CNIC </label>
                  <Form.Item
                    name="cnic"
                    rules={[
                      { required: true, message: "Please enter valid CNIC" },
                      { max: 13, message: "CNIC cannot exceed 13 characters" },
                      () => ({
                        validator(_, value) {
                          if (value && value.length > 13) {
                            return Promise.reject(
                              new Error("CNIC cannot exceed 13 characters")
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Please enter your CNIC"
                      type="text"
                      maxLength={13}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  </Form.Item>
                </>
              )}
            </div>
          </Form>

          {isMobile ? (
            <Col
              className="marginBottom10 marginTop10"
              style={{ margin: "auto" }}
              flex={
                isMobile || isTablet
                  ? "96%"
                  : ProductDetail?.images?.length === 1
                    ? "90%"
                    : "96%"
              }
            >
              <Text
                className="marginBottom5"
                style={{ display: "block", minWidth: "max-content" }}
              >
                Quantity
              </Text>
              <Row
                className={
                  "quantity-add-to-cart-mobile" +
                    (isMobile && !ProductDetail?.pre_order) ||
                    (isTablet && !ProductDetail?.pre_order)
                    ? "width100 flexBetween"
                    : (isMobile && ProductDetail?.pre_order) ||
                      (isTablet && ProductDetail?.pre_order)
                      ? "width100 flexBetween"
                      : isDesktop && !ProductDetail?.pre_order
                        ? "width100 flexBetween marginBottom10 paddingTop10"
                        : isDesktop && ProductDetail?.pre_order
                          ? "width100 flexBetween marginBottom10 paddingTop10"
                          : ""
                }
              >
                <Col>
                  <Space
                    className={
                      isMobile || isTablet
                        ? "width100 flexEnd"
                        : "width100 flexEnd"
                    }
                  >
                    <InputNumber
                      className={
                        isMobile || isTablet
                          ? "MobileQuantityPicker"
                          : "QuantityPicker"
                      }
                      type="number"
                      readOnly={true}
                      controls={false}
                      value={ProductQuantity}
                      placeholder={ProductQuantity}
                      defaultValue={ProductQuantity}
                      addonBefore={
                        <Text
                          className="cursorPointer"
                          onClick={() => quantityDecrement()}
                        >
                          -
                        </Text>
                      }
                      addonAfter={
                        <Text
                          className="cursorPointer"
                          onClick={() => quantityIncrement()}
                        >
                          +
                        </Text>
                      }
                    />
                  </Space>
                </Col>
                <Col className="flexEnd paddingLeft5">
                  {ProductInWishList ? (
                    <div
                      style={{
                        backgroundColor: "black",
                        borderRadius: "10px",
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        height={16}
                        width={16}
                        src={wishlistedIcon.src}
                        style={{
                          marginBottom: "2px",
                        }}
                        onClick={() => removeProductFromWishlist()}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: mainColor,
                        borderRadius: "10px",
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        height={16}
                        width={16}
                        src="/heart-icon-1.svg"
                        style={{
                          marginBottom: "2px",
                        }}
                        onClick={() => addProductToWishlist()}
                      />
                    </div>
                  )}
                  {/* {isMobile || isTablet ? null : ( */}
                  <Box alignSelf="center">
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Box sx={{ position: "relative" }}>
                        <div
                          className="cursor-pointer"
                          onClick={handleClick}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            marginLeft: "10px",
                          }}
                        >
                          <Image height={20} width={20} src={ShareIcon} />
                        </div>
                        {openSharePopup && (
                          <Box className="share-icon-popup-style">
                            {showCopyDiv && (
                              <Box>
                                <Typography
                                  style={{ fontSize: "10px" }}
                                  className="copied-text-dialog"
                                >
                                  Copied!
                                </Typography>
                              </Box>
                            )}
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <a
                                href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div style={{ cursor: "pointer" }}>
                                  <FacebookIcon className="small-icon" />
                                </div>
                              </a>

                              <a
                                href={`https://wa.me/?text=I Want to Buy Product - ${ProductDetail?.title} - ${window.location.href}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div style={{ cursor: "pointer" }}>
                                  <WhatsAppIcon className="small-icon" />
                                </div>
                              </a>

                              <a
                                href={`mailto:?subject=${ProductDetail?.title}&body=${window.location.href}`}
                              >
                                <div style={{ cursor: "pointer" }}>
                                  <MailIcon className="small-icon" />
                                </div>
                              </a>

                              <div
                                style={{ cursor: "pointer" }}
                                onClick={copyTextToClipboard}
                              >
                                <ContentCopyIcon className="small-icon" />
                              </div>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </ClickAwayListener>
                  </Box>
                  {/* )} */}
                </Col>
                {ProductDetail?.sold_out === false && !isMobile ? (
                  !productSoldout ? (
                    !selectSizeError ? (
                      <Col flex="30%" style={{ marginLeft: "30px" }}>
                        <DynamicButton
                          type="primary"
                          name={
                            addToCartLoader
                              ? "Adding..."
                              : viewBagItemsState
                                ? "View Cart"
                                : ProductDetail?.pre_order
                                  ? "Pre Book"
                                  : "Add to Cart"
                          }
                          function={handleAddToCartClick}
                          // function={addProduct}
                          // function={addProductToCart}
                          className="primary_btn poppinsMedium"
                          icon={
                            addToCartLoader && (
                              <Spin
                                indicator={
                                  <RedoOutlined
                                    spin
                                    className="spinner-redo-white"
                                    fontSize={"20px"}
                                    style={{
                                      color: "white",
                                    }}
                                  />
                                }
                              />
                            )
                          }
                          style={{
                            height: "40px",
                            fontSize: "16px",
                            fontFamily: "ZemestroStd-Medium",
                            backgroundColor: !addToCartLoader
                              ? viewBagItemsState
                                ? "black"
                                : "#FF0D50"
                              : "#FF0D50",
                          }}
                        />
                      </Col>
                    ) : (
                      <Col flex="55%" style={{ marginLeft: "10px" }}>
                        <DynamicButton
                          type="primary"
                          name="Select Size "
                          // function={addProduct}
                          // function={addProductToCart}
                          className="primary_btn poppinsMedium"
                          icon={
                            <Image
                              height={23}
                              width={23}
                              src="/inch-1.svg"
                              style={{
                                marginRight: "-7px",
                                marginBottom: "2px",
                                color: "white",
                              }}
                            />
                          }
                          style={{
                            height: "40px",
                            fontSize: "16px",
                            fontFamily: "ZemestroStd-Medium",
                            backgroundColor: !addToCartLoader
                              ? viewBagItemsState
                                ? "black"
                                : "#FF0D50"
                              : "#FF0D50",
                          }}
                        />
                      </Col>
                    )
                  ) : (
                    <Col flex="55%" style={{ marginLeft: "10px" }}>
                      <DynamicButton
                        type="primary"
                        name="Sold Out"
                        disabled={true}
                        property="danger"
                        className="primary_btn poppinsMedium"
                        // icon={<HiOutlineShoppingBag color="white" />}
                        style={{
                          height: "40px",
                          fontSize: "16px",
                          fontFamily: "ZemestroStd-Medium",
                        }}
                      />
                    </Col>
                  )
                ) : (
                  isDesktop && (
                    <Col flex="55%" style={{ marginLeft: "10px" }}>
                      <DynamicButton
                        type="primary"
                        name="Sold Out"
                        disabled={true}
                        property="danger"
                        className="primary_btn poppinsMedium"
                        // icon={<HiOutlineShoppingBag color="white" />}
                        style={{
                          height: "40px",
                          fontSize: "16px",
                          fontFamily: "ZemestroStd-Medium",
                        }}
                      />
                    </Col>
                  )
                )}
              </Row>
              {ProductDetail?.vendor?.green_fusion && (
                <div className="green-fusion-cart-text">
                  <p>
                    If you don't have a credit card? {""}
                    <Link
                      href="/greenfusion-form"
                      className="green-fusion-link"
                    >
                      Click here
                    </Link>
                    for financing
                  </p>
                </div>
              )}
            </Col>
          ) : null}
          {/* //* Product Options Size, Color etc ---------------------------------------------> */}

          <Col
            flex={
              isMobile || isTablet
                ? "100%"
                : ProductDetail?.images?.length === 1
                  ? "99%"
                  : "96%"
            }
          >
            {ProductDetail?.is_stitch ? (
              <StitchingModule
                handleProductDiscountedPrice={handleProductDiscountedPrice}
                handleProductOrignalPrice={handleProductOrignalPrice}
                userAgent={userAgent}
                ProductDetail={ProductDetail}
                SelectedVariant={SelectedVariant}
                EnableStitching={EnableStitching}
                setEnableStitching={setEnableStitching}
                DefaultStitching={DefaultStitching}
                setDefaultStitching={setDefaultStitching}
                LineItemAddons={LineItemAddons}
                setLineItemAddons={setLineItemAddons}
                setViewBagItemsState={setViewBagItemsState}
                pushedVariants={pushedVariants}
                setPushedVariants={setPushedVariants}
                availableVariants={availableVariants}
                ProductQuantity={ProductQuantity}
                setProductQuantity={setProductQuantity}
                setSelectedVariant={setSelectedVariant}
                productSoldout={productSoldout}
                setProductSoldout={setProductSoldout}
                selectedValueFromOptions={selectedValueFromOptions}
                setSelectedValueFromOptions={setSelectedValueFromOptions}
                handleOptionChange={handleOptionChange}
                viewBagItemsState={viewBagItemsState}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                showDrawer={showDrawer}
                addProduct={addProduct}
                addToCartLoader={addToCartLoader}
                setaddToCartLoader={setaddToCartLoader}
              // isSizeChartOpen={isSizeChartOpen}
              // setIsSizeChartOpen={setIsSizeChartOpen}
              />
            ) : (
              ProductDetail?.has_variants && (
                <ProductOptions
                  handleProductDiscountedPrice={handleProductDiscountedPrice}
                  handleProductOrignalPrice={handleProductOrignalPrice}
                  availableVariants={availableVariants}
                  ProductQuantity={ProductQuantity}
                  setProductQuantity={setProductQuantity}
                  userAgent={userAgent}
                  ProductDetail={ProductDetail}
                  SelectedVariant={SelectedVariant}
                  setSelectedVariant={setSelectedVariant}
                  productSoldout={productSoldout}
                  setProductSoldout={setProductSoldout}
                  LineItemAddons={LineItemAddons}
                  setLineItemAddons={setLineItemAddons}
                  selectedValueFromOptions={selectedValueFromOptions}
                  setSelectedValueFromOptions={setSelectedValueFromOptions}
                  handleOptionChange={handleOptionChange}
                  viewBagItemsState={viewBagItemsState}
                  setViewBagItemsState={setViewBagItemsState}
                  pushedVariants={pushedVariants}
                  setPushedVariants={setPushedVariants}
                  selectSizeError={selectSizeError}
                  setSelectSizeError={setSelectSizeError}
                  openDrawer={openDrawer}
                  setOpenDrawer={setOpenDrawer}
                  showDrawer={showDrawer}
                  addProduct={addProduct}
                  addToCartLoader={addToCartLoader}
                  setaddToCartLoader={setaddToCartLoader}
                // isSizeChartOpen={isSizeChartOpen}
                // setIsSizeChartOpen={setIsSizeChartOpen}
                />
              )
            )}
          </Col>

          {/* //* Desktop & Mobile View Price & Quantity Picker -------------------------------> */}

          {/* //* Desktop View Buttons Sections -----------------------------------------------> */}

          {isDesktop ? (
            <Col
              flex={
                isMobile || isTablet
                  ? "100%"
                  : ProductDetail?.images?.length === 1
                    ? "90%"
                    : "96%"
              }
            >
              <Text
                className="font16 poppinsSemiBold font600"
                style={{ display: "block", minWidth: "max-content" }}
              >
                Quantity
              </Text>
              <Row
                className={
                  (isMobile && !ProductDetail?.pre_order) ||
                    (isTablet && !ProductDetail?.pre_order)
                    ? "width100 flexStart quantity-add-to-cart-desktop"
                    : (isMobile && ProductDetail?.pre_order) ||
                      (isTablet && ProductDetail?.pre_order)
                      ? "width100 flexStart quantity-add-to-cart-desktop"
                      : isDesktop && !ProductDetail?.pre_order
                        ? "width100 flexStart marginBottom10 paddingTop10 quantity-add-to-cart-desktop"
                        : isDesktop && ProductDetail?.pre_order
                          ? "width100 flexStart  marginBottom10 paddingTop10 quantity-add-to-cart-desktop"
                          : ""
                }
              >
                <Col>
                  <Space
                    className={
                      isMobile || isTablet
                        ? "width100 flexEnd"
                        : "width100 flexEnd"
                    }
                  >
                    <InputNumber
                      style={{
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        marginTop: "3px",
                      }}
                      className={
                        isMobile || isTablet
                          ? "MobileQuantityPicker"
                          : "QuantityPicker"
                      }
                      type="number"
                      readOnly={true}
                      controls={false}
                      value={ProductQuantity}
                      placeholder={ProductQuantity}
                      defaultValue={ProductQuantity}
                      addonBefore={
                        <Text
                          style={{ marginTop: "5px" }}
                          className="cursorPointer font30Bold"
                          onClick={() => quantityDecrement()}
                        >
                          -
                        </Text>
                      }
                      addonAfter={
                        <Text
                          style={{ marginTop: "5px" }}
                          className="cursorPointer font30Bold"
                          onClick={() => quantityIncrement()}
                        >
                          +
                        </Text>
                      }
                    />
                  </Space>
                </Col>

                {ProductDetail?.sold_out === false && isDesktop ? (
                  !productSoldout ? (
                    !selectSizeError ? (
                      <Col flex="30%" style={{ marginLeft: "30px" }}>
                        <DynamicButton
                          type="primary"
                          name={
                            addToCartLoader
                              ? "Adding..."
                              : viewBagItemsState
                                ? "View Cart"
                                : ProductDetail?.pre_order
                                  ? "Pre Book"
                                  : "Add to Cart"
                          }
                          function={handleAddToCartClick}
                          // function={addProduct}
                          // function={addProductToCart}
                          className="primary_btn poppinsMedium add-to-cart-button"
                          icon={
                            addToCartLoader && (
                              <Spin
                                indicator={
                                  <RedoOutlined
                                    spin
                                    className="spinner-redo-white"
                                    fontSize={"20px"}
                                    style={{
                                      color: "white",
                                    }}
                                  />
                                }
                              />
                            )
                          }
                          style={{
                            height: "40px",
                            fontSize: "16px",
                            fontFamily: "ZemestroStd-Medium",
                            backgroundColor: !addToCartLoader
                              ? viewBagItemsState
                                ? "black"
                                : "#FF0D50"
                              : "#FF0D50",
                          }}
                        />
                      </Col>
                    ) : (
                      <Col flex="55%" style={{ marginLeft: "10px" }}>
                        <DynamicButton
                          type="primary"
                          name="Select Size "
                          // function={addProduct}
                          // function={addProductToCart}
                          className="primary_btn poppinsMedium"
                          icon={
                            <Image
                              height={23}
                              width={23}
                              src="/inch-1.svg"
                              style={{
                                marginRight: "-7px",
                                marginBottom: "2px",
                                color: "white",
                              }}
                            />
                          }
                          style={{
                            height: "40px",
                            fontSize: "16px",
                            fontFamily: "ZemestroStd-Medium",
                            backgroundColor: !addToCartLoader
                              ? viewBagItemsState
                                ? "black"
                                : "#FF0D50"
                              : "#FF0D50",
                          }}
                        />
                      </Col>
                    )
                  ) : (
                    <Col flex="30%" style={{ marginLeft: "10px" }}>
                      <DynamicButton
                        type="primary"
                        name="Sold Out"
                        disabled={true}
                        property="danger"
                        className="primary_btn poppinsMedium"
                        // icon={<HiOutlineShoppingBag color="white" />}
                        style={{
                          height: "40px",
                          fontSize: "16px",
                          fontFamily: "ZemestroStd-Medium",
                        }}
                      />
                    </Col>
                  )
                ) : (
                  isDesktop && (
                    <Col flex="30%" style={{ marginLeft: "10px" }}>
                      <DynamicButton
                        type="primary"
                        name="Sold Out"
                        disabled={true}
                        property="danger"
                        className="primary_btn poppinsMedium add-to-cart-button"
                        icon={<HiOutlineShoppingBag color="white" />}
                        style={{
                          height: "40px",
                          fontSize: "16px",
                          fontFamily: "ZemestroStd-Medium",
                        }}
                      />
                    </Col>
                  )
                )}
              </Row>
              {ProductDetail?.vendor?.green_fusion && (
                <div className="green-fusion-cart-text">
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "nowrap",
                    }}
                  >
                    If you don't have a credit card?{" "}
                    <Link
                      href="/greenfusion-form"
                      className="green-fusion-link"
                      style={{ margin: "0 4px" }}
                    >
                      Click here
                    </Link>
                    for financing
                  </p>
                </div>
              )}
            </Col>
          ) : null}

          {paymentFeatureModal && (
            <PaymentFeatureModal
              ProductTitle={ProductDetail?.title}
              handleProductDiscountedPrice={handleProductDiscountedPrice()}
              isBNPL={ProductDetail?.is_bnpl}
              AllBankPlans={
                SelectedVariant?.variant_detail?.all_bank_installment_plans
              }
              BAFLCardPlans={
                SelectedVariant?.variant_detail?.commission_installment_plans
              }
              addProduct={addProduct}
              selectedBAFLPlan={selectedBAFLPlan}
              setSelectedBAFLPlan={setSelectedBAFLPlan}
              selectedAllBankPlan={selectedAllBankPlan}
              setSelectedAllBankPlan={setSelectedAllBankPlan}
              addProductInstallmentPlan={addProductInstallmentPlan}
              visible={handlePaymentFeatureModalVisible}
              setPaymentFeatureModal={setPaymentFeatureModal}
              paymentFeatureModal={paymentFeatureModal}
            />
          )}
          <Modal
            footer={null}
            // open={true}
            open={ShowNotifyModal}
            title="Notify on Restock"
            // className="NotifyModal"
            wrapClassName="NotifyModal"
            onCancel={showNotificationModal}
          >
            <Col span={24}>
              <Form
                size="large"
                layout="vertical"
                name="notify_form"
                onFinish={notifyUser}
                className="notify_form"
                initialValues={{
                  remember: true,
                }}
                style={{
                  width: "100%",
                }}
              >
                <Col>
                  <Text className="font16">Name</Text>
                  <Form.Item
                    // label="Name"
                    name="full_name"
                    style={{ margin: "10px 0px" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valid name!",
                      },
                      // { min: 3, message: "Too Short!" },
                      { max: 40, message: "Too Long!" },
                      {
                        pattern: /^[aA-zZ\s]+$/,
                        message: "Only alphabets are allowed for this field ",
                      },
                    ]}
                  >
                    <Input placeholder="Full Name" />
                  </Form.Item>

                  <Text className="font16">Email</Text>
                  <Form.Item
                    // label="Email"

                    name="email"
                    style={{ margin: "10px 0px" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valid email!",
                      },
                      {
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email!",
                      },
                    ]}
                  >
                    <Input placeholder="John@example.com" />
                  </Form.Item>

                  <Text className="font16">Mobile</Text>
                  <Form.Item
                    // label="Mobile"
                    name="phoneNo"
                    className="marginTop10"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <PhoneInput country={"pk"} />
                  </Form.Item>
                </Col>
                <Form.Item>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    style={{
                      height: "40px",
                      fontWeight: 600,
                      fontSize: "16px",
                      // minWidth: "422px",
                      marginTop: "20px",
                      borderRadius: "6px",
                      background: "black",
                    }}
                  >
                    Notify on Restock
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Modal>

          {/* //* Mobile View Sticky Buttons Sections -----------------------------------------------> */}

          <Col
            // lg={{ span: 0 }}
            // xs={{ span: 24 }}
            span={isDesktop ? 0 : 24}
            className="backgroundLight padding5 product-buying-bottom-nav"
            style={{
              left: "0px",
              right: "0px",
              zIndex: "5",
              bottom: "70px",
              position: "fixed",
            }}
          >
            <Row
              className={
                (isMobile && !ProductDetail?.pre_order) ||
                  (isTablet && !ProductDetail?.pre_order)
                  ? "width100 marginBottom25"
                  : (isMobile && ProductDetail?.pre_order) ||
                    (isTablet && ProductDetail?.pre_order)
                    ? "width100 flexCenter marginBottom25"
                    : isDesktop && ProductDetail?.pre_order
                      ? "width100 flexCenter marginTop10 marginBottom10"
                      : isDesktop && !ProductDetail?.pre_order
                        ? "width100 flexCenter marginTop10 marginBottom10"
                        : ""
              }
            >
              {ProductDetail?.sold_out === false ? (
                !ProductDetail?.pre_order && null
              ) : (
                // <Col flex="49%" onClick={!ProductInCart ? buyItNow : null}>
                //   <DynamicButton
                //     name={"Buy Now"}
                //     type="primary"
                //     className="primary_btn_outline"
                //     style={{
                //       height: "40px",
                //       // fontSize: "16px",
                //       borderRadius: "3px",
                //       textTransform: "capitalize",
                //       fontSize: isDesktop ? "16px" : "14px",
                //     }}
                //   />
                // </Col>
                <Col flex="49%" onClick={() => setShowNotifyModal(true)}>
                  <DynamicButton
                    type="primary"
                    name="Notify on Restock"
                    className="primary_btn_outline"
                    style={{
                      height: "40px",
                      fontSize: "16px",
                      borderRadius: "3px",
                      textTransform: "capitalize",
                      fontSize: isDesktop ? "16px" : "14px",
                    }}
                  />
                </Col>
              )}

              {ProductDetail?.sold_out === false ? (
                <Row
                  justify="space-between"
                  style={{ width: "100%" }}
                  className="bottom-cart-buttons-navigation"
                >
                  <Col
                    span={11}
                    alignSelf="center"
                    // span={12}
                    width="90%"
                  >
                    {productSoldout ? (
                      <DynamicButton
                        type="primary"
                        name="Sold Out"
                        disabled={true}
                        property="danger"
                        className="primary_btn poppinsMedium"
                        // icon={<HiOutlineShoppingBag color="white" />}
                        style={{
                          height: "40px",
                          fontSize: "16px",
                          fontFamily: "ZemestroStd-Medium",
                        }}
                      />
                    ) : !selectSizeError ? (
                      <Col flex="30%">
                        <DynamicButton
                          type="primary"
                          name={
                            addToCartLoader
                              ? "Adding..."
                              : viewBagItemsState
                                ? "View Cart"
                                : ProductDetail?.pre_order
                                  ? "Pre Book"
                                  : "Add to Cart"
                          }
                          function={handleAddToCartClick}
                          // function={addProduct}
                          // function={addProductToCart}
                          className="primary_btn poppinsMedium"
                          icon={
                            addToCartLoader && (
                              <Spin
                                indicator={
                                  <RedoOutlined
                                    spin
                                    className="spinner-redo-white"
                                    fontSize={"20px"}
                                    style={{
                                      color: "white",
                                    }}
                                  />
                                }
                              />
                            )
                          }
                          style={{
                            height: "40px",
                            fontSize: "16px",
                            fontFamily: "ZemestroStd-Medium",
                            backgroundColor: !addToCartLoader
                              ? viewBagItemsState
                                ? "black"
                                : "#FF0D50"
                              : "#FF0D50",
                          }}
                        />
                      </Col>
                    ) : (
                      <Col flex="55%">
                        <DynamicButton
                          type="primary"
                          name="Select Size "
                          // function={addProduct}
                          // function={addProductToCart}
                          className="primary_btn poppinsMedium"
                          icon={
                            <Image
                              height={23}
                              width={23}
                              src="/inch-1.svg"
                              style={{
                                marginRight: "-7px",
                                marginBottom: "2px",
                                color: "white",
                              }}
                            />
                          }
                          style={{
                            height: "40px",
                            fontSize: "16px",
                            fontFamily: "ZemestroStd-Medium",
                            backgroundColor: !addToCartLoader
                              ? viewBagItemsState
                                ? "black"
                                : "#FF0D50"
                              : "#FF0D50",
                          }}
                        />
                      </Col>
                    )}

                    {/* <DynamicButton
                      width="100%"
                      type="primary"
                      name={
                        addToCartLoader
                          ? "Adding..."
                          : viewBagItemsState
                          ? "View Cart"
                          : ProductDetail?.pre_order
                          ? "Pre Book"
                          : "Add to Cart 3"
                      }
                      className="primary_btn poppinsMedium no-gap"
                      // function={handlePaymentFeatureModalVisible}
                      function={addProduct}
                      // icon={<HiOutlineShoppingBag color="white" />}
                      icon={
                        // <LoadingOutlined style={{ fontSize: 24 }} spin />
                        !addToCartLoader ? null : (
                          // <img src={handBag.src} height="auto" width="auto" />
                          <Spin
                            indicator={
                              <RedoOutlined
                                spin
                                className="spinner-redo-white"
                                fontSize={"20px"}
                                style={{
                                  color: "white",
                                }}
                              />
                            }
                          />
                        )
                      }
                      style={{
                        height: "40px",
                        borderRadius: "3px",
                        fontWeight: 600,
                        // textTransform: "capitalize",
                        fontSize: isDesktop ? "16px" : "10px",
                        backgroundColor: !addToCartLoader
                          ? viewBagItemsState
                            ? "black"
                            : "#FF0D50"
                          : "#FF0D50",
                      }}
                    /> */}
                  </Col>
                  <Col
                    span={11}
                    alignSelf="center"
                    // span={12}
                    // width="60%"
                    onClick={handleSellerClick}
                  >
                    <DynamicButton
                      width="100%"
                      type="primary"
                      name={"Chat with Merchant"}
                      className="primary_btn poppinsMedium no-gap"
                      style={{
                        height: "40px",
                        borderRadius: "3px",
                        fontWeight: 600,
                        // textTransform: "capitalize",
                        fontSize: isDesktop ? "16px" : "10px",
                        backgroundColor: !addToCartLoader
                          ? viewBagItemsState
                            ? "black"
                            : "#FF0D50"
                          : "#FF0D50",
                      }}
                    />
                  </Col>
                  {ProductDetail?.vendor?.green_fusion && (
                    <div className="green-fusion-cart-text">
                      <p>
                        If you don't have a credit card?{" "}
                        <Link
                          href="/greenfusion-form"
                          className="green-fusion-link"
                          style={{ margin: "0 4px" }}
                        >
                          Click here
                        </Link>
                        for financing
                      </p>
                    </div>
                  )}
                </Row>
              ) : (
                <Col flex="49%">
                  <DynamicButton
                    type="primary"
                    name="Sold Out"
                    disabled={true}
                    property="danger"
                    className="primary_btn poppinsMedium"
                    // icon={<HiOutlineShoppingBag color="white" />}
                    style={{
                      height: "40px",
                      fontSize: "16px",
                      borderRadius: "3px",
                      textTransform: "capitalize",
                      fontSize: isDesktop ? "16px" : "14px",
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        {url && isQrCategory && (
          <div className="qr-code marginTop10 marginBottom10">
            <QRCode value={url} />
          </div>
        )}
        {isMobile && (
          <Col flex={"96%"}>
            <ProductDescription
              Color={"red"}
              Fabric={Fabric}
              userAgent={userAgent}
              ProductDetail={ProductDetail}
            />
          </Col>
        )}
      </Spin>
      <LoginPage
        ShowLoginModal={ShowLoginModal}
        setShowLoginModal={setShowLoginModal}
      />
    </Col>
  );
}
