import React from "react";
import { Row, Col, Typography } from "antd";
import "./ProductInfo.scss";
import Cookies from "js-cookie";

const { Text } = Typography;

const ProductInfo = ({ product }) => {
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  return (
    <div className="product-info-container">
      <Row
        justify="space-between"
        className="product-info-row border borderRadius5"
      >
        <Col span={11}>
          <div className="product-info-block">
            <Text className="product-info-title">Price:</Text>
            <Text className="product-info-value">
              {defaultCurrencyFromCookies} {product.price}
            </Text>
          </div>
          <div className="product-info-block">
            <Text className="product-info-title">Display:</Text>
            <Text className="product-info-value">{product.display}</Text>
          </div>
          <div className="product-info-block">
            <Text className="product-info-title">Front Camera:</Text>
            <Text className="product-info-value">{product.frontCamera}MP</Text>
          </div>
        </Col>
        <Col span={2} className="divider-col">
          <div className="divider" />
        </Col>
        <Col span={11}>
          <div className="product-info-block">
            <Text className="product-info-title">Rear Camera:</Text>
            <Text className="product-info-value">{product.rearCamera}MP</Text>
          </div>
          <div className="product-info-block">
            <Text className="product-info-title">Ram:</Text>
            <Text className="product-info-value">{product.ram}GB</Text>
          </div>
          <div className="product-info-block">
            <Text className="product-info-title">Storage:</Text>
            <Text className="product-info-value">{product.storage}GB</Text>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProductInfo;
