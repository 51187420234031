import { message } from 'antd';
import { ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';
import React from 'react';

export const showMessageWithCustomClose = (text) => {
  const key = text; // Unique key for the message

  // Show the message with a custom close icon
  message.open({
    key,
    content: (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {/* Warning icon and message text */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ExclamationCircleOutlined style={{ color: 'red', marginRight: 8 }} /> {/* Warning Icon */}
          <span>{text}</span> {/* Message Text */}
        </div>

        {/* Close icon */}
        <CloseOutlined
          style={{ cursor: 'pointer', marginLeft: '10px' }}
          onClick={() => message.destroy(key)} // Manually close the message
        />
      </div>
    ),
    duration: 0, // Keep the message open until manually closed
  });
};
