"use client";
//? Library Imports ------------------------------------------------------------>
import { Row, Col, Typography, Button, Image } from "antd";
import { useCallback, useState, useRef, useEffect } from "react";
import Cookies from "js-cookie";

//? Modules -------------------------------------------------------------------->
import ProductCard from "@/components/Shared/ProductsCarousel/ProductCard/ProductCard";
import OnlyForYouCarousel from "@/components/shared/ProductsCarousel/OnlyForYouCarousel";
import NextIcon from "../../../../assets/Icons/Nexticon.svg";
import PreviousIcon from "../../../../assets/Icons/PreviousIcon.svg";
import "../../../../components/Homepage/HomePage.scss";
import "./SimilarProducts.scss";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function SimilarProducts({
  handle,
  userAgent,
  RelatedProducts,
}) {
  const { isMobile, isTablet, isDesktop } = userAgent;
  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");

  // Refs for horizontal scrolling
  const scrollRef = useRef(null);

  //? States --------------------------------------------------------------------->
  const [Products, setProducts] = useState(RelatedProducts?.results || []);
  const [ActivePage, setActivePage] = useState(2);
  const [ShowLoader, setShowLoader] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const TotalProducts = RelatedProducts?.count;
  const TotalPages = Math.ceil(TotalProducts / 5);
  const HasMoreData = ActivePage < TotalPages;

  //? Functions ------------------------------------------------------------------>
  const fetchSimilarProducts = useCallback(async () => {
    try {
      setShowLoader(true);
      const res = await fetch(
        process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/product/related_products/${handle}?limit=${
            isMobile ? 16 : 5
          }&page=${ActivePage}&storefront=${defaultCurrencyIdFromCookies}`
      );
      if (!res.ok) {
        throw new Error("RelatedProducts Api Failed!");
      }

      const data = await res.json();
      setProducts((prevProducts) => [...prevProducts, ...data?.results]);
      setActivePage((prevPage) => prevPage + 1);
      setShowLoader(false);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error.message);
      return null;
    }
  }, [ActivePage, handle]);

  //? Update scroll button visibility ------------------------------------------->
  const updateScrollButtonsVisibility = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
  };

  //? Scroll functions ---------------------------------------------------------->
  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
  };

  //? Check scroll position on load and resize ---------------------------------->
  useEffect(() => {
    if (scrollRef.current) {
      updateScrollButtonsVisibility();
      scrollRef.current.addEventListener(
        "scroll",
        updateScrollButtonsVisibility
      );
    }
    return () => {
      scrollRef.current?.removeEventListener(
        "scroll",
        updateScrollButtonsVisibility
      );
    };
  }, []);

  return (
    Products?.length && (
      <Col
        span={24}
        className={
          isMobile || isTablet
            ? "padding10"
            : "backgroundLight margin20 padding30 border borderRadius5"
        }
      >
        {!isMobile && (
          <>
            <Row
              justify="space-between"
              align="middle"
              className="similar-products-header"
            >
              <Text
                className={isMobile || isTablet ? "font14Bold" : "font20Bold"}
              >
                You may also like
              </Text>

              <div className="scroll-buttons">
                <Button className="scroll-button" onClick={scrollLeft}>
                  <Image
                    preview={false}
                    src={PreviousIcon.src}
                    className="scroll-icon"
                  />
                </Button>

                <Button className="scroll-button" onClick={scrollRight}>
                  <Image
                    preview={false}
                    src={NextIcon.src}
                    className="scroll-icon"
                  />
                </Button>
              </div>
            </Row>

            <div
              ref={scrollRef}
              className="product-scroll-container no-scrollbar"
            >
              {Products?.map((product, index) => (
                <div key={index} className="product-card-wrapper">
                  <ProductCard product={product} userAgent={userAgent} />
                </div>
              ))}
            </div>
          </>
        )}

        {isMobile && (
          <div className="product-carousel-main">
            <OnlyForYouCarousel
              data={Products}
              title="You may also like"
              userAgent={{ isMobile, isTablet, isDesktop }}
            />
          </div>
        )}
      </Col>
    )
  );
}
