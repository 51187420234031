"use client";
//? Library Imports ------------------------------------------------------------>
import { Row, Col, message, Typography } from "antd";
import { useCallback, useState } from "react";
import Cookies from "js-cookie";

//? Modules -------------------------------------------------------------------->
import Loader from "@/components/Shared/Loader/Loader";
// import MediaQueryHook from "@/hooks/useMediaQuery/MediaQueryHook";
import DynamicButton from "@/components/Shared/Buttons/DynamicButton/DynamicButton";
import ProductCard from "@/components/Shared/ProductsCarousel/ProductCard/ProductCard";

import "../../../../components/Homepage/HomePage.scss";
import ProductHorizontalCard from "@/components/shared/ProductsCarousel/ProductCard/ProductHorizontalCard/ProductHorizontalCard";
import ProductsCarousel from "@/components/shared/ProductsCarousel/ProductsCarousel";
import ProductForYouCard from "@/components/shared/ProductsCarousel/ProductCard/ProductForYouCard/ProductForYouCard";
//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function YouMayLike({ userAgent, RelatedProducts }) {
  //? Hooks -------------------------------------------------------------------->
  // const { isMobile, isTablet, isDesktop } = MediaQueryHook();
  const { isMobile, isTablet, isDesktop } = userAgent;

  //? States --------------------------------------------------------------------->
  const [Products, setProducts] = useState(RelatedProducts?.results);

  //? Functions ------------------------------------------------------------------>

  return Products?.length ? (
    <Col
      span={24}
      className={
        isMobile || isTablet
          ? "padding10"
          : "backgroundLight padding10 border borderRadius5"
      }
    >
      {!isMobile && (
        <>
          <Text
            className={
              isMobile || isTablet
                ? "font14Bold paddingLeft10"
                : "font16Bold paddingLeft10"
            }
          >
            You may like
          </Text>

          <Row
            justify="start"
            className={isMobile ? "flex width100" : "flex width100 "}
            style={{ gap: "1%" }}
          >
            {Products?.map((product, index) => (
              <Col
                key={index}
                style={{
                  padding: "0",
                  margin: "auto",
                  width: "100%",
                }}
              >
                <ProductForYouCard product={product} userAgent={userAgent} />
              </Col>
            ))}
          </Row>
        </>
      )}
      {isMobile && (
        <div
          className="product-carousel-main"
          style={{
            margin: "auto",
            maxWidth: "100%",
          }}
        >
          <ProductsCarousel
            data={Products}
            title="Frequently Bought Products"
            userAgent={{ isMobile, isTablet, isDesktop }}
            // type={"homepage"}
          />
        </div>
      )}
    </Col>
  ) : null;
}
